<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">

      <div style="margin-top: 20px;">
        <span>Изменение даты планируемой отгрузки:</span>
        <div class="form__item">
          <CustomDate required="true" :value="this.formData.date_shipping_plan || ''"
          @change_date="(value) => onChange('date_shipping_plan', value)" />
        </div>
      </div>

      <div class="form__item">
        <CustomInput label="Комментарии" :value="this.formData.date_shipping_comment"
          @clear_input="onChange('date_shipping_comment', '')" type="textarea"
          @change="(value) => onChange('date_shipping_comment', value)" />
      </div>

      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomDate from "@/components/form/CustomDate";
import CustomInput from "@/components/form/CustomInput";


export default {
  name: "provider-requests-shipping-date-change",
  components: { CustomModalFooter, CustomDate, CustomInput },
  props: ['data'],
  data() {
    return {
      loading: false,
      provider_request_id: this.data.providerRequestId || '',
      formData: {
        provider_request_id_arr: this.data.body?.provider_request_id_arr || '',
        provider_request_item_id_arr: this.data.body?.provider_request_item_id_arr || '',
        date_shipping_plan: '',
        date_shipping_comment: '',
      },
      providerOptions: [],
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData, this.provider_request_id)
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
// TODO решить с размером, чтобы был фикисрованный, но при слишком маленьких/больших размерах изменялся
.form {
  display: grid;
  flex-wrap: wrap;
  gap: 20px;
  width: 300px;

  .form__item {
    flex: 1 1 45%;
  }
}
</style>