<template>
    <div :class="[{ purpose: this.data?.body?.purpose }]">
        <q-form @submit="this.onSubmit" class="form">
            <div class="form__item">
                <CustomInput label="Кол-во в ед.отпуска" type="number" :controls="true"
                    :value="this.formData.qty_delivery" @change_input="(value) => onChange('qty_delivery', value)"
                    required="true" />
            </div>
            <CustomModalFooter :btn-loading="this.btnLoading" />
        </q-form>
    </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomInput from "@/components/form/CustomInput";

export default {
    name: "provider-requests-qty-delivery-chng",
    components: { CustomCheckbox, CustomModalFooter, CustomInput },
    props: ['data'],
    data() {
        return {
            loading: false,
            btnLoading: false,
            formData: {
                provider_request_item_id: this.data?.body?.provider_request_item_id || '',
                provider_request_id: this.data?.body?.provider_request_id || '',
                qty_delivery: '',
            },
        }
    },
    methods: {
        onChange(key, value) {
            this.formData[key] = value;
        },
        async onSubmit() {
            this.btnLoading = true
            await this.data?.onSubmit(this.formData)
            this.btnLoading = false
        },
    },
}
</script>

<style scoped lang="scss">
.form {
    display: grid;
    min-width: 500px;
    grid-template-columns: 12fr;
    gap: 20px;

    @media (max-width: 991px) {
        min-width: 50%;
        max-width: 100%;
        grid-template-columns: 12fr;
    }
}
</style>