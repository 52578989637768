<template>
  <span>
    <q-icon
      :class="['icon', `icon__${this.icon}`, {icon__small: this.small, icon__green: this.green, icon__hover: this.hoverMode, icon__primary: this.primary}]"
      :name="this.iconName"
    >
      <PopupConfirm
        :title="this.approveTitle"
        v-if="this.approveMode || !!this.approve"
        :loading="!!this.loading"
        :approve="this.approve"
      />
      <q-tooltip v-if="!!this.title">{{ this.title }}</q-tooltip>
    </q-icon>
  </span>
</template>

<script>
import PopupConfirm from "@/components/common/PopupConfirm";

export default {
  name: "CustomIcon",
  props: {
    loading: Boolean,
    approve: Function,
    icon: String,
    title: String,
    approveTitle: String,
    approveMode: Boolean,
    green: Boolean,
    primary: Boolean,
    hoverMode: Boolean,
    small: Boolean
  },
  components: {PopupConfirm},
  computed: {
    iconName() {
      if(this.icon === 'trash_restore')
        return 'fa-solid fa-trash-can-arrow-up icon__trash'
      if(this.icon === 'trash')
        return 'fa-solid fa-trash'
      if(this.icon === 'file')
        return 'fa-solid fa-file'
      return this.icon
    }
  }
}
</script>

<style scoped lang="scss">
.icon {
  font-size: 20px;
  transition: all .4s;
  cursor: pointer;

  &__small {
    font-size: 15px;
  }

  &__hover {
    opacity: .7;
    &:hover {
      opacity: 1;
    }
  }
  &__edit {
    color: #c4541e;
  }

  &__trash {
    color: #9d1010;

    &:hover {
      color: #e53b3b;
    }
  }

  &__green {
    color: #2d8006;

    &:hover {
      color: #3ca607;
    }
  }

  &__primary {
    color: #027BE3;

    &:hover {
      color: #0062b8;
    }
  }
}
</style>
