<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">

      <div class="form__item">
        <CustomSelect :options="warehouseOptions" label="Склад" :required="true" optionValueKey="warehouse_id"
          optionLabelKey="warehouse_name" :value="this.formData.warehouse_id"
          @change="(value) => onChange('warehouse_id', value)" />
      </div>

      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import { getWarehouses } from "@/views/ProviderRequests/services";

export default {
  name: "prov-req-change-warehouse",
  components: { CustomModalFooter, CustomSelect },
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        warehouse_id: '',
        provider_request_id: this.data.provider_request_id || ''
      },
      warehouseOptions: [],
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
    async fetchWarehouses() {
      this.warehouseOptions = await getWarehouses();
    },
  },
  mounted() {
    this.fetchWarehouses();
  }
}
</script>

<style scoped lang="scss">
// TODO решить с размером, чтобы был фикисрованный, но при слишком маленьких/больших размерах изменялся
.form {
  display: grid;
  flex-wrap: wrap;
  gap: 20px;
  width: 550px;

  .form__item {
    flex: 1 1 45%;
  }
}
</style>