<template>
  <div style="z-index: 0">
    <span
      v-if="this.paginationPosition !== 'bottom' && !this.hideTopTitle"
      class="pagination__title"
      >{{ this.paginationTitle }}</span
    >
    <q-table
      :class="{'custom-table': true, 'custom-table__striped': this.striped === false, 'custom-table__expand': !!this.expand, 'custom-table__small': !!this.small, 'custom-table__smallFont': !!this.smallFont, 'custom-table__default': !this.expand, 'my-sticky-header-table': this.stickyHeader !== false && !!this.rowsData?.length }"
      :title="title"
      :table-class="{expand: !!this.expand, 'not_expand': !this.expand}"
      :rows="computedRowsData || []"
      :columns="columns"
      :wrap-cells="true"
      :dense="this.dense || false"
      :style="{maxHeight: this.maxHeightData, minHeight: '95px'}"
      :row-key="rowKey"
      :pagination="this.showPagination ? pagination : {rowsPerPage: 10000}"
      no-results-label="Ничего не найдено"
      no-data-label="Ничего не найдено"
      loading-label="Загрузка"
      :hide-pagination="true"
      :hide-bottom="!!computedRowsData?.length"
      :loading="this.loading"
    >
      <template v-slot:top v-if="this.showSearch || this.showHeaderAdd">
        <div class="q-table__title">{{ this.title }}</div>
        <q-space style="width: 15px" />
        <div class="actions">
          <CustomInput
            v-if="this.showSearch"
            :value="this.searchTerm"
            :isSearch="true"
            label="Поиск"
            v-debounce:300="this.onSearchChange"
          />
          <q-btn
            v-if="this.showHeaderAdd"
            size="md"
            title="Добавить"
            color="primary"
            :disable="this.headerAddDisabled"
            dense
            @click="() => onAddClick && onAddClick()"
            icon="add"
          />
          <q-btn
            class="actions__btn"
            v-if="this.updateData"
            @click="this.updateData"
          >
            <i class="material-icons actions__update"> refresh </i>
          </q-btn>
        </div>
      </template>
      <template v-if="this.$slots['top-right']" v-slot:top-right="props">
        <slot name="top-right"></slot>
      </template>
      <template v-slot:no-data="{ icon, message, filter }">
        <div class="full-width row flex-center q-gutter-sm">
          <span>
            {{ message }}
          </span>
          <q-icon size="2em" :name="filter ? 'filter_b_and_w' : icon" />
        </div>
      </template>
      
      <template v-if="customHeader" v-slot:header="props">
        <slot name="customHeader" v-bind="props"></slot>
      </template>
      <template v-if="checkbox || expand || addBtn" v-slot:header="props">
        <q-tr :props="props">
          <q-th auto-width v-if="checkbox">
            <CustomCheckbox
              :value="checkboxValue"
              @change="(e) => onAllCheckClick && onAllCheckClick(e)"
            />
          </q-th>
          <q-th v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
          </q-th>
          <q-th auto-width v-if="expand">
            <q-btn
              size="sm"
              title="Закрыть все"
              color="deep-purple-13"
              round
              dense
              @click="() => onAllExpandClick && onAllExpandClick()"
              icon="remove"
            />
          </q-th>
          <q-th auto-width v-else-if="addBtn">
            <q-btn
              size="sm"
              :title="!!this.addBtnTitle ? '' : 'Добавить'"
              color="deep-purple-13"
              round
              dense
              @click="() => onAddClick && onAddClick()"
              icon="add"
            >
              <q-tooltip
                v-if="!!this.addBtnTitle"
                >{{ this.addBtnTitle }}</q-tooltip
              >
            </q-btn>
          </q-th>
        </q-tr>
      </template>

      <template v-if="customBody" v-slot:body="props">
        <slot name="customBody" v-bind="{...props, onExpandClick}"></slot>
      </template>
    </q-table>

    <span
      v-if="!this.showPagination && !this.hideFooterTitle && this.paginationPosition === 'bottom'"
      class="pagination__title"
      >{{ this.paginationTitle }}</span
    >

    <div
      v-if="this.showPagination && !!computedRowsData?.length"
      class="row justify-center bi-align-center q-mt-md pagination"
    >
      <q-pagination
        v-model="pagination.page"
        color="grey-8"
        @update:model-value="handlePaginationChange"
        :max="pagesNumber"
        max-pages="6"
        size="sm"
      />
      <span
        v-if="this.paginationPosition === 'bottom'"
        class="pagination__title"
        >{{ this.paginationTitle }}</span
      >
    </div>
  </div>
</template>

<script>
import CustomLoader from "@/components/common/CustomLoader";
import CustomInput from "@/components/form/CustomInput";
import {filteredGroupRowsData, filteredRowsData} from "@/components/common/CustomTable/services";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import {getPaginationTableTitle} from "@/services";

export default {
  name: "CustomTable",
  components: {CustomCheckbox, CustomInput, CustomLoader},
  props: [
    "expand",
    "checkbox",
    "addBtn",
    "customHeader",
    "addBtnTitle",
    "showHeaderAdd",
    "headerAddDisabled",
    "dense",
    "checkboxValue",
    "customBody",
    "title",
    "rowsData",
    "rowsDataGroupMode",
    "groupProps",
    "stickyHeader",
    "onAllCheckClick",
    "onAllExpandClick",
    "onAddClick",
    "columns",
    "showPagination",
    "paginationPosition",
    "hideFooterTitle",
    "hideTopTitle",
    "onPaginationChange",
    "pageHeaderHeight",
    "getDetailData",
    "showSearch",
    "callDetailWhenExpand",
    "rowKey",
    "striped",
    "small",
    "smallFont",
    "loading",
    "updateData",
    "pagesNumber",
    "count",
  ],
  data() {
    return {
      searchTerm: "",
      itemsLoading: {},
      pagination: {
        page: 1,
        rowsPerPage: 100,
        sortBy: "desc",
        descending: false,
      },
    };
  },
  computed: {
    paginationTitle() {
      if (!this.showPagination) return `Количество: ${this.rowsData?.length || 0}`
      return getPaginationTableTitle('', this.pagination.rowsPerPage, this.count, this.pagination.page)
    },
    computedRowsData() {
      if (!this.searchTerm) return this.rowsData
      if(this.rowsDataGroupMode)
        return filteredGroupRowsData(this.columns, this.rowsData, this.searchTerm, this.groupProps)
      return filteredRowsData(this.columns, this.rowsData, this.searchTerm)
    },
    maxHeightData() {
      const screenHeight = window.innerHeight
      const margin = 15
      const pagination = this.showPagination ? 30 : 0
      if (screenHeight < 730 && this.pageHeaderHeight > 300)
        return '600px'
      let totalHeight = screenHeight - 98 - (this.pageHeaderHeight || 0) - pagination - margin
      if(totalHeight < 500) totalHeight = 500
      return this.stickyHeader ? `${totalHeight}px` : 'initial'
    }
  },
  methods: {
    async onExpandClick(id, expand) {
      if (!this.callDetailWhenExpand && !expand) return;
      this.itemsLoading[id] = true;
      await this.getDetailData(id, expand);
      this.itemsLoading[id] = false;
    },
    handlePaginationChange(page) {
      this.pagination.page = page;
      this.onPaginationChange(page);
    },
    onSearchChange(value) {
      this.searchTerm = value
    },
  },
};
</script>

<style lang="sass">
.custom-table
  z-index: 0

  thead
    tr
      th
        position: relative
      th.sortable
        padding-right: 15px !important

        &
          transition: all .1s
          &:hover
            background-color: #f1f1f1

        &.text-right
          padding-right: 8px !important
          padding-left: 15px !important
          i
            right: unset
            left: 2px

        i
          font-size: 100% !important
          margin-left: 0
          position: absolute
          right: 2px
          vertical-align: middle
          top: calc(100% - 50% - 6px)
  &__small
    th, td
      padding: 4px 6px !important
  &__smallFont
    th, td
      font-size: 11px !important

  tbody
    tr
      background-color: #fff

  &__default
    .not_expand
    tbody
      tr:nth-child(2n)
        background-color: #eeeeee !important

      tr:nth-child(4n - 1)
        background-color: #fff !important

  &__expand
    .expand
    tbody
      tr:nth-child(2n)
        background-color: #fff

      tr:nth-child(4n - 1)
        background-color: #eeeeee

  &__striped
    tbody
      td
        background-color: #fff !important

.my-sticky-header-table
  thead tr th
    background-color: #fff
    position: sticky !important
    z-index: 1

  thead tr:first-child th
    top: 0

.actions
  display: flex
  align-items: flex-end
  gap: 15px

  &__btn
    background-color: #3f66ad !important
    color: #fff

  &__update
    font-size: 20px !important
    color: #fff !important

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    background-color: #fff

  thead tr th
    position: sticky !important
    z-index: 1

  thead tr:first-child th
    top: 0

  &.q-table--loading thead tr:last-child th
    top: 48px

@media (max-width: 768px)
  .my-sticky-header-table
    min-height: 550px !important

.pagination
  align-items: center

  &__title
    font-size: 11px
    color: #616161
    font-weight: 500
    margin-left: 25px
</style>
