import instance from "@/services/api";

export const providerRequestsAPI = {
  async getList(body) {
    return await instance()
      .post("/provider_request/read/", body)
      .then((res) => res?.data);
  },
  async getProviderRequestItem(provider_request_id) {
    return await instance()
      .get(`/provider_request/${provider_request_id}/items/`)
      .then((res) => res?.data);
  },
  async getProviderRequestContractors() {
    return await instance()
      .get("/client_request/common/contractors/read/")
      .then((res) => res?.data);
  },
  async getProviderRequestShippingDateHistory(provider_request_id) {
    return await instance()
      .get(
        `/provider_request/${provider_request_id}/read_provider_shipping_hist`
      )
      .then((res) => res?.data);
  },
  async updateProviderRequestContractor(body) {
    return await instance()
      .post("/provider_request/provider_request_change_contractor/", body)
      .then((res) => res?.data);
  },

  async changeProviderRequestTypeCommon(body) {
    return await instance()
      .post("/provider_request/provider_request_change/type/", body)
      .then((res) => res?.data);
  },
  async changeProviderRequestProviderCommon(body) {
    return await instance()
      .post("/provider_request/provider_request_change/provider/", body)
      .then((res) => res?.data);
  },
  async setProviderRequestBuh(body) {
    return await instance()
      .post("/provider_request/provider_request_set_carried_buh/", body)
      .then((res) => res?.data);
  },
  async changeProviderRequestDateShipping(body) {
    return await instance() 
      .post("/provider_request/upd_provider_request_date_shipping/", body)
      .then((res) => res?.data);
  },  
  
  async getDocsTableData(provider_request_id) {
    return await instance() 
    .get(`/provider_request/${provider_request_id}/read_provider_request_document/`, )
    .then((res) => res?.data);
  },  
  async getDocsClientData(remont_id) {
    return await instance() 
    .get(`/common/client/${remont_id}/client_info/`, )
    .then((res) => res?.data);
  },  
  async getDocsProviderInfo(provider_request_id) {
    return await instance() 
    .get(`/provider_request/${provider_request_id}/provider_request_info/`, )
    .then((res) => res?.data);
  },  
  async getDocsTypes(is_partner) {
    return await instance() 
    .get(`/common/document_types/${is_partner}/provider_request/`)
    .then((res) => res?.data);
  },  
  async getInvoiceOptions(provider_request_id) {
    return await instance() 
    .get(`/provider_request/${provider_request_id}/request_invoice_main/`)
    .then((res) => res?.data);
  },  
  async generateInvoice(body) {
    return await instance() 
    .post("/provider_request/insert_provider_request_invoice/", body)
    .then((res) => res?.data);
  },  
  async refundInvoice(body) {
    return await instance() 
    .post("/provider_request/refund_invoice/", body)
    .then((res) => res?.data);
  },  
  async getInvoiceList(provider_request_id) {
    return await instance() 
    .get(`/provider_request/${provider_request_id}/request_invoice/`)
    .then((res) => res?.data);
  },  
  async refundRelease(body) {
    return await instance() 
    .post("/provider_request/refund_release/", body)
    .then((res) => res?.data);
  },  
  async getStatusHistoryList(provider_request_id) {
    return await instance() 
    .get(`/provider_request/${provider_request_id}/item_hist/`)
    .then((res) => res?.data);
  },  
  async getWarehousesForRelease() {
    return await instance() 
    .get("/provider_request/warehouse_read_fs/")
    .then((res) => res?.data);
  },  
  async getWarehouses() {
    return await instance() 
    .get("/provider_request/warehouse_fs/")
    .then((res) => res?.data);
  },  
  async changeWarehouse(body) {
    return await instance() 
    .post("/provider_request/change_warehouse/", body)
    .then((res) => res?.data);
  },  
  async releaseCreate(body) {
    return await instance() 
    .post("/provider_request/release_create/", body)
    .then((res) => res?.data);
  },  
  
  async changeQtyDelivery(body) {
    return await instance() 
    .post("provider_request/qty_change/", body)
    .then((res) => res?.data);
  },  
  async getProviderRequestInvoiceItem(provider_request_invoice_item_id) {
    return await instance() 
    .get(`/provider_request/${provider_request_invoice_item_id}/get_provider_request_invoice_item/`)
    .then((res) => res?.data);
  },  
  async submitInvoiceItem(body) {
    return await instance() 
      .post("/provider_request/upd_provider_request_invoice_item/", body)
      .then((res) => res?.data);
  },  
  async onDeleteInvoiceItem(provider_request_invoice_item_id) {
    return await instance() 
      .delete(`/provider_request/${provider_request_invoice_item_id}/del_invoice_item/`)
      .then((res) => res?.data);
  },  
  async onDeleteInvoice(provider_request_invoice_id) {
    return await instance() 
      .delete(`/provider_request/${provider_request_invoice_id}/del_invoice/`)
      .then((res) => res?.data);
  },  
  async getProviderRequestStatuses(provider_request_id) {
    return await instance()
      .get(`/provider_request/${provider_request_id}/read_provider_request_status_for_select/`)
      .then((res) => res?.data);
  },
  async setProviderRequestStatus(body) {
    return await instance() 
      .post("/provider_request/set_provider_material_item_status_remont/", body)
      .then((res) => res?.data);
  },  
  async moveProviderReqItem(provider_request_id) {
    return await instance().post("provider_request/move_provider_request_item/", {
        provider_request_id
      }).then((res) => res?.data);
  },  
  async sendTo1CInvoice(provider_request_invoice_id, is_send_to_1c) {
    return await instance().post("/provider_request/send_to_1c_invoice/", {
      provider_request_invoice_id,
      is_send_to_1c,
    },).then((res) => res?.data);
  },
  async getMaterialTypes(){
    return await instance()
      .get("/provider_request/material_type/")
      .then((res) => res?.data);
  },
  async getReasons(){
    return await instance()
      .get("/provider_request/reasons/")
      .then((res) => res?.data);
  },
  async getActiveMaterialsList(){
    return await instance()
      .get("/provider_request/material_active/")
      .then((res) => res?.data);
  },
  async getReplacementMaterialsList(provider_request_item_id){
    return await instance()
      .get(`/provider_request/${provider_request_item_id}/material_by_type/`)
      .then((res) => res?.data);
  },
  async getMaterialListClearTableData(body){
    return await instance()
      .post("/provider_request/clear_fill/", body)
      .then((res) => res?.data);
  },
  async getMaterialListOnAddTableData(body){
    return await instance()
      .post("/provider_request/material_add/", body)
      .then((res) => res?.data);
  },
  async getMaterialListZamena(body){
    return await instance()
      .post("/provider_request/material_analog/", body)
      .then((res) => res?.data);
  },
  async materialCntChangeNewReq(body){
    return await instance()
      .post("/provider_request/get_qty_item_cnt/", body)
      .then((res) => res?.data);
  },
  async createAdditionalReq(body){
    return await instance()
      .post("/provider_request/create_additional/", body)
      .then((res) => res?.data);
  },
};