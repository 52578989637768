import instance from "@/services/api";

export const techprojectDevelopersAPI = {
  async getList() {
    return await instance()
      .get("/handbook/techproject_developer/read/")
      .then((res) => res?.data);
  },
  async addTechprojectDeveloper(body) {
    return await instance()
      .post(`/handbook/techproject_developer/add/`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res?.data);
  },
  async updateTechprojectDeveloper(techproject_developer_id, body) {
    return await instance()
      .put(
        `/handbook/techproject_developer/${techproject_developer_id}/update/`,
        body
      )
      .then((res) => res?.data);
  },

  async deleteTechprojectDeveloper(techproject_developer_id) {
    return await instance()
      .delete(
        `handbook/techproject_developer/${techproject_developer_id}/delete/`
      )
      .then((res) => res?.data);
  },

  async sendTo1C(techproject_developer_id, is_send_to_1c) {
    return await instance().post("handbook/techproject_developer/set_send_to_1c_techproject_developer/", {
      techproject_developer_id,
      is_send_to_1c,
    },).then((res) => res?.data);
  },
};
