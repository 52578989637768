import { providerRequestsAPI } from "@/views/ProviderRequests/api";

export const sendTo1C = async (provider_request_invoice_id, is_send_to_1c) => {
  try {
    const response = await providerRequestsAPI.sendTo1CInvoice(
      provider_request_invoice_id,
      is_send_to_1c
    );
    return response?.data;
  } catch (error) {}
};

export const invoiceListTable = [
  {
    name: "provider_request_invoice_id",
    label: "ID",
    field: "provider_request_invoice_id",
    align: "center",
    sortable: true,
  },
  {
    name: "invoice_num",
    label: "Номер",
    field: "invoice_num",
    align: "center",
  },
  {
    name: "invoice_date",
    label: "Дата",
    field: "invoice_date",
    align: "center",
  },
  {
    name: "document_name",
    label: "Наименование",
    field: "document_name",
    align: "center",
  },
  {
    name: "org_name",
    label: "Организация",
    field: "org_name",
    align: "center",
  },
  {
    name: "dwnld",
    label: "",
    field: "dwnld",
    align: "center",
  },
  {
    name: "fio",
    label: "Исполнитель",
    field: "fio",
    align: "center",
  },
  {
    name: "fio",
    label: "Проведен",
    field: "fio",
    align: "center",
  },
  {
    name: "1с",
    label: "",
    field: "1с",
    align: "center",
  },
  {
    name: "del",
    label: "",
    field: "del",
    align: "center",
  },
];
