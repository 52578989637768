export const shippingDateHistoryTable = [
    {
      name: "rowIndex",
      label: "ID",
      field: "rowIndex",
      align: "center",
    }, 
    {
      name: "material_name",
      label: "Материал",
      field: "material_name",
      align: "center",
    },
    {
      name: "date_shipping_old",
      label: "Старая дата отгрузки",
      field: "date_shipping_old",
      align: "center",
    },
    {
      name: "date_shipping_new",
      label: "Новая дата отгрузки",
      field: "date_shipping_new",
      align: "center",
    },
    {
      name: "date_shipping_comment",
      label: "Комментарии",
      field: "date_shipping_comment",
      align: "center",
    },
    {
      name: "fio",
      label: "Работник",
      field: "fio",
      align: "center",
    },
    {
      name: "rowversion",
      label: "Дата изменения",
      field: "rowversion",
      align: "center",
    },    
  ]