<template>
  <q-linear-progress indeterminate v-if="this.loading" />
  <div v-else class="request__wrapper">
    <div ref="header">
      <Header :on-toggle-collapse="this.onToggleCollapse" :setData="this.setData"/>
      <Tabs :onTabChange="this.onTabChange" :activeTab="this.component"/>
    </div>
    <div class="content">
      <component
          :is="this.component" :headerHeight="this.headerHeight" :requestData="this.data"
          :client_request_id="this.client_request_id"
      />
    </div>
  </div>
</template>

<script>
import Header from "@/views/ClientRequests/header";
import Tabs from "@/views/ClientRequests/client-request-tabs";
import Requisites from "@/views/ClientRequests/requisites";
import RemontMarkStep from "@/views/ClientRequests/remontMarkStep";
import ResponsibleOfficial from "@/views/ClientRequests/responsibleOfficial";
import TextConstructor from "@/views/ClientRequests/textConstructor";
import ReceiveKeys from "@/views/ClientRequests/receiveKeys";
import GprStage from "@/views/ClientRequests/gprStage";
import RemontStages from "@/views/ClientRequests/remontStages";
import RemontProjects from "@/views/ClientRequests/remontProjects";
import RemontPayments from "@/views/ClientRequests/remontPayments";
import RemontDocs from "@/views/ClientRequests/remontDocs";
import RemontMaterials from "@/views/ClientRequests/remontMaterials";
import RemontMaterialsSaleUnits from "@/views/ClientRequests/remontMaterialsSaleUnits";
import RemontSmeta from "@/views/ClientRequests/remontSmeta";
import RemontSmetaWhole from "@/views/ClientRequests/remontSmetaWhole";
import RemontDS from "@/views/ClientRequests/remontDS";
import RemontDSLegacy from "@/views/ClientRequests/remontDS/dsLegacy";
// TODO: Удалить прошлую страницу
import ProviderRequests from "@/views/ProviderRequests";
import RemontMaterialSmeta from "@/views/ClientRequests/remontMaterialSmeta";
import RemontChat from "@/views/ClientRequests/remontChat";
import WarrantyRequests from "@/views/ClientRequests/warrantyRequests";
import RemontMeasure from "@/views/ClientRequests/remontMeasure";
import TechFeatures from "@/views/ClientRequests/techFeatures";
import CheckList from "@/views/ClientRequests/checkList";
import ContractorAgreement from "@/views/ClientRequests/contractorAgreement";
import RemontTimeline from "@/views/ClientRequests/remontTimeline";
import RemontServices from "@/views/ClientRequests/remontServices";
import {useRoute} from "vue-router/dist/vue-router";
import {checkIsPermissionExist} from "@/views/ClientRequests/services";

export default {
  name: "ClientsRequest",
  components: {
    Requisites,
    Header,
    Tabs,
    ResponsibleOfficial,
    RemontMarkStep,
    ReceiveKeys,
    TextConstructor,
    GprStage,
    RemontStages,
    RemontPayments,
    RemontDocs,
    RemontDS,
    RemontDSLegacy,
    ProviderRequests,
    RemontMaterialSmeta,
    RemontChat,
    ContractorAgreement,
    WarrantyRequests,
    RemontMeasure,
    CheckList,
    RemontTimeline,
    RemontServices,
    RemontProjects,
    RemontMaterials,
    RemontMaterialsSaleUnits,
    RemontSmeta,
    RemontSmetaWhole,
    TechFeatures,
  },
  data() {
    return {
      data: null,
      component: '',
      headerHeight: null,
      loading: true,
    }
  },
  mounted() {
    this.checkPermission()
  },
  methods: {
    async checkPermission() {
      const res = await checkIsPermissionExist(+this.$route?.params?.client_request_id)
      if(res) {
        this.loading = false
        this.onToggleCollapse()
        return
      }
      this.$router.push('/404')
    },
    onTabChange(value) {
      this.component = value
    },
    setData(data) {
      this.data = data
    },
    onToggleCollapse() {
      const timeout = setTimeout(() => {
        this.getHeaderHeight()
        clearTimeout(timeout)
      }, 100)
    },
    getHeaderHeight() {
      this.headerHeight = this.$refs.header?.clientHeight || null
    }
  },
  setup() {
    const route = useRoute()
    return {client_request_id: +route?.params?.client_request_id};
  }
}
</script>

<style scoped lang="scss">
.content {
  margin-top: 20px;
}
</style>
