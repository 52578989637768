import { handbookProvidersAPI } from "@/views/handbook/Providers/api";
import { handbookContractorsAPI } from "@/views/handbook/contractors/api";

export const getHandbookProviders = async () => {
  try {
    const res = await handbookProvidersAPI.getList();
    return res?.data;
  } catch (e) {}
};
export const addHandbookProvider = async (body = {}) => {
  const formData = new FormData();
  Object.entries(body).forEach(([key, value]) => {
    formData.append(key, value);
  });
  try {
    const res = await handbookProvidersAPI.addProvider(formData);
    return res?.data;
  } catch (e) {
  }
};

export const updateHandbookProvider = async (provider_id, body = {}) => {
  try {
    const res = await handbookProvidersAPI.updateProvider(provider_id, body);
    return res?.data;
  } catch (e) {}
};
export const deleteHandbookProvider = async (provider_id) => {
  try {
    const res = await handbookProvidersAPI.deleteProvider(provider_id);
    return res?.data;
  } catch (e) {}
};
export const getCity = async () => {
  try {
    const res = await handbookContractorsAPI.getCity();
    return res?.data;
  } catch (e) {}
};
export const getProviderType = async () => {
  try {
    const res = await handbookProvidersAPI.getProviderType();
    return res?.data;
  } catch (e) {}
};
export const getProviderCountry = async () => {
  try {
    const res = await handbookProvidersAPI.getProviderCountry();
    return res?.data;
  } catch (e) {}
};
export const getContragentType = async () => {
  try {
    const res = await handbookContractorsAPI.getContragentType();
    return res?.data;
  } catch (e) {}
};
export const sendTo1C = async (provider_id, is_send_to_1c) => {
  try {
    const response = await handbookProvidersAPI.sendTo1C(
      provider_id,
      is_send_to_1c,
    );
    return response?.data;
  } catch (error) {}
};
export const providersHandbookColumns = [
  {
    name: "provider_id",
    field: "provider_id",
    label: "ID",
    align: "center",
  },
  {
    name: "provider_name",
    field: "provider_name",
    label: "Название",
    align: "center",
  },
  {
    name: "is_not_create_auto_payment",
    field: "is_not_create_auto_payment",
    label: "Запрет на создание автоматических платежей",
    align: "center",
  },
  {
    name: "material_marzha",
    field: "material_marzha",
    label: "Маржа материала",
    align: "center",
  },
  { name: "is_nds", field: "is_nds", label: "Плателщик НДС", align: "center" },
  {
    name: "director_fio",
    field: "director_fio",
    label: "ФИО директора",
    align: "center",
  },
  // { name: "documents", field: "documents", label: "Документы", align: "center" },
  { name: "phone", field: "phone", label: "Телефон", align: "center" },
  { name: "address", field: "address", label: "Адрес", align: "center" },
  { name: "city_name", field: "city_name", label: "Город", align: "center" },
  { name: "guid", field: "guid", label: "ГУИД", align: "center" },
  { name: "bin", field: "bin", label: "БИН", align: "center" },
  { name: "kbe", field: "kbe", label: "КБе", align: "center" },
  { name: "iik", field: "iik", label: "ИИК", align: "center" },
  {
    name: "bank_name",
    field: "bank_name",
    label: "Наименование банка",
    align: "center",
  },
  {
    name: "contract_text",
    field: "contract_text",
    label: "Текст для договора",
    align: "center",
  },

  { name: "comment", field: "comment", label: "Комментарии", align: "center" },

  { name: "email", field: "email", label: "Email", align: "center" },

  {
    name: "is_auto_gpr",
    field: "is_auto_gpr",
    label: "Отправка заявок",
    align: "center",
  },
  {
    name: "is_subscribe",
    field: "is_subscribe",
    label: "Подписка на рассылку",
    align: "center",
  },

  {
    name: "is_send_to_1c_text",
    field: "is_send_to_1c_text",
    label: "1C",
    align: "center",
  },
  { name: "edit", field: "edit", label: "Ред.", align: "center" },
  { name: "delete", field: "delete", label: "Удалить", align: "center" },
];
