export const statusHistoryTable = [
  {
    name: "id",
    label: "ID",
    field: "id",
    align: "center",
    sortable: true,
  },
  {
    name: "name",
    label: "Наименование",
    field: "name",
    align: "center",
  },
  {
    name: "status",
    label: "Статус",
    field: "status",
    align: "center",
  },
  {
    name: "dateChange",
    label: "Дата изменения",
    field: "dateChange",
    align: "center",
  },
  {
    name: "does",
    label: "Исполнитель",
    field: "does",
    align: "center",
  },
];
