import instance from "@/services/api";

export const handbookContractorsAPI = {
  async getList() {
    return await instance()
      .get("/handbook/contractor/read/")
      .then((res) => res?.data);
  },

  async sendTo1C(contractor_id, is_send_to_1c) {
    return await instance().post("handbook/contractor/set_send_to_1c_contractor/", {
      contractor_id,
      is_send_to_1c,
    },).then((res) => res?.data);
  },
  async addContractor(body) {
    return await instance()
      .post("/handbook/contractor/add/", body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res?.data);
  },
  async updateContractor(contractor_id, body) {
    return await instance()
      .put(`/handbook/contractor/${contractor_id}/update/`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res?.data);
  },
  async deleteContractor(contractor_id) {
    return await instance()
      .delete(`handbook/contractor/${contractor_id}/delete/`)
      .then((res) => res?.data);
  },
  async getCity() {
    return await instance()
      .get(`/common/cities/read_city/`)
      .then((res) => res?.data);
  },
  async getTaxopark() {
    return await instance()
      .get(`/common/taxoparks/read/`)
      .then((res) => res?.data);
  },
  async getContractorType() {
    return await instance()
      .get(`/common/contractors/type/`)
      .then((res) => res?.data);
  },
  async getContragentType() {
    return await instance()
      .get(`/common/contragents/type/`)
      .then((res) => res?.data);
  },
  async getContractorMainType() {
    return await instance()
      .get(`/common/contractors/read_main/`)
      .then((res) => res?.data);
  },
};
