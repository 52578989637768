<template>
  <div class="wrapper">
    <CustomTable title="" :rowsData="this.invoiceListData" :columns="this.invoiceListTable" :custom-body="true"
      :small="true" :hide-footer-title="true" :sticky-header="false">
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="right">
            {{ props.row.provider_request_invoice_id }}
          </custom-table-cell>
          <custom-table-cell align="left">
            <span v-html="`${props.row.invoice_num}`"></span>
          </custom-table-cell>
          <custom-table-cell align="center">
            {{ props.row.invoice_date }}
          </custom-table-cell>
          <custom-table-cell align="left">
            <span v-html="`${props.row.document_name}`"></span>
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.org_name }}
            {{ props.row.org_bin }}
          </custom-table-cell>
          <custom-table-cell align="center">
            <span v-if="props.row.document_id">
              <Clickable :link="true" :href="getFullUrl(`/dict/document/document_id/${props.row.document_id}`)">
                Скачать
              </Clickable>
            </span>
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.fio }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.is_carried_text }}
          </custom-table-cell>
          <custom-table-cell align="left">
            {{ props.row.is_send_to_1c_text }}<br>
            <span v-if="props.row.is_send_to_1c === 0 || props.row.is_send_to_1c === 2">
              <q-btn :loading="btnLoading[props.row?.provider_request_invoice_id]"
                @click="sendTo1CHandler(props.row?.provider_request_invoice_id, props.row?.is_send_to_1c)"
                color="primary" size="sm" label="Отправить в 1С" dense /><br>
            </span>
            {{ props.row.guid }}
          </custom-table-cell>
          <custom-table-cell align="center">
            <CustomIcon icon="trash" :approve-mode="true"
              :loading="this.delBtnLoading[props.row?.provider_request_invoice_id]"
              :approve="() => this.onDeleteInvoice(props.row?.provider_request_invoice_id)" />
          </custom-table-cell>
        </q-tr>
      </template>
    </CustomTable>
    <CustomModalFooter :onCancel="this.closeModal" :hide-submit-btn="true" cancel-btn-text="Закрыть" />
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import Clickable from "@/components/common/Clickable";
import CustomIcon from "@/components/common/CustomIcon.vue";
import { getInvoiceList, onDeleteInvoice } from "@/views/ProviderRequests/services";
import { invoiceListTable, sendTo1C } from "./services";
import { getFullUrl } from "@/services";

export default {
  name: "prov-req-invoice-list",
  components: { CustomTableCell, CustomTable, CustomModalFooter, Clickable, CustomIcon },
  props: ['data', 'closeModal'],
  data() {
    return {
      invoiceListTable,
      invoiceListData: [],
      btnLoading: {},
      delBtnLoading: {},
    }
  },
  methods: {
    getFullUrl,
    async fetchInvoiceData(providerRequestId) {
      this.invoiceListData = await getInvoiceList(providerRequestId);
    },
    async sendTo1CHandler(provider_request_invoice_id, is_send_to_1c) {
      this.btnLoading = { ...this.btnLoading, [provider_request_invoice_id]: true };
      const response = await sendTo1C(provider_request_invoice_id, is_send_to_1c);
      this.btnLoading = { ...this.btnLoading, [provider_request_invoice_id]: false };
      if (!response) return
      this.showSucceedSnackbar("Успешно отправлено в 1С");
      this.fetchInvoiceData(this.data.providerRequestId)
    },
    async onDeleteInvoice(provider_request_invoice_id) {
      this.delBtnLoading = { ...this.delBtnLoading, [provider_request_invoice_id]: true }; 
      const res = await onDeleteInvoice(provider_request_invoice_id);
      this.delBtnLoading = { ...this.delBtnLoading, [provider_request_invoice_id]: false };
      if (!res) return
      // TODO обновление itemData в соответсвии с его id
      this.showSucceedSnackbar()
    },
  },
  mounted() {
    this.fetchInvoiceData(this.data.providerRequestId);
  }


}
</script>

<style scoped lang="scss">
// Настроить размер модалки 
.wrapper {
  width: 940px;
}

@media (max-width: 576px) {
  .wrapper {
    max-width: 100%;
  }
}
</style>