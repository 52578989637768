<template>
    <div class="wrapper">
        <CustomTable title="" :rowsData="this.historyTableData" :columns="this.shippingDateHistoryTable"
            :custom-body="true" :small="true" :hide-footer-title="true" :sticky-header="false">
            <template #customBody="props">
                <q-tr>
                    <custom-table-cell align="right">
                        <!-- Отображаем порядковый номер -->
                        {{ props.rowIndex + 1 }}
                    </custom-table-cell>
                    <custom-table-cell align="left">
                        {{ props.row.material_name }}
                    </custom-table-cell>
                    <custom-table-cell align="center">
                        {{ props.row.date_shipping_old }}
                    </custom-table-cell>
                    <custom-table-cell align="center">
                        {{ props.row.date_shipping_new }}
                    </custom-table-cell>
                    <custom-table-cell align="left">
                        {{ props.row.date_shipping_comment }}
                    </custom-table-cell>
                    <custom-table-cell align="center">
                        {{ props.row.fio }}
                    </custom-table-cell>
                    <custom-table-cell align="center">
                        {{ props.row.rowversion }}
                    </custom-table-cell>
                </q-tr>
            </template>
        </CustomTable>
        <CustomModalFooter :onCancel="this.closeModal" :hide-submit-btn="true" cancel-btn-text="Закрыть" />
    </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import { shippingDateHistoryTable } from "./services";

import { getProviderRequestShippingDateHistory } from "@/views/ProviderRequests/services";


export default {
    name: "provider-requests-shipping-date-history",
    components: { CustomTableCell, CustomTable, CustomModalFooter },
    props: ['data', 'closeModal'],
    data() {
        return {
            shippingDateHistoryTable,
            historyTableData: []
        }
    },
    methods: {
        async fetchShippingDatesHistory(provider_request_id) {
            this.historyTableData = await getProviderRequestShippingDateHistory(provider_request_id);
            console.log(this.historyTableData);

        },
    },
    mounted() {
        this.fetchShippingDatesHistory(this.data.provider_request_id);
    }


}
</script>

<style scoped lang="scss">
// Настроить размер модалки 
.wrapper {
    width: 940px;
}

@media (max-width: 576px) {
    .wrapper {
        max-width: 100%;
    }
}
</style>