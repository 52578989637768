import {PAGES, PAGES_HEADS} from "@/constants";
import {createRouter, createWebHistory} from "vue-router";
import Login from "../views/Login/index.vue";
import LoginUrl from "../views/loginUrl/index.vue";
import HomeView from "../views/Main.vue";
import FinancePayments from "../views/FinancePayments";
import ForgetPassword from "../views/ForgetPassword";
import Costs from "@/views/Costs";
import RemontRealizations from "@/views/RemontRealizations";
import ChangeMaterialRequests from "@/views/ChangeMaterialRequests";
import DataWith1C from "@/views/DataWith1C";
import TimelineGrade from "@/views/timeline/timeline-grade";
import TimelineRemont from "@/views/timeline/timeline-remont";
import ClientsRequest from "@/views/ClientRequests";
import ProviderRequests from "@/views/ProviderRequests";
import FastSearch from "@/views/FastSearch";
import AgreementsJournal from "@/views/AgreementsJournal";
import Handbook from "@/views/handbook";
import Logging from "@/views/logging";
import Profile from "@/views/profile";
import ExecutiveReport from "@/views/ExecutiveReport";
import Kanban from "@/views/kanban";
import WarrantyRequests from "@/views/WarrantyRequests";
import ClientRequestByRemontId from "@/views/ClientRequests/ClientRequestByRemontId";
import NotFound from "@/views/page404";
import {handbookTitles} from "@/views/timeline/services";
import ReportGenerator from "@/views/ReportGenerator";
import TechprojectRequests from "@/views/techprojectRequests";
// TODO delete when docEdit page for Provider Requests table will be done, it will be used as a component later, for now this is needede for dev.
import ProviderDocEdit from "@/views/ProviderRequests/providerDocEdit.vue";

export const routes = [
  {
    path: PAGES.login,
    name: "login",
    meta: {layout: "empty", title: 'Авторизация'},
    component: Login,
  },
  {
    path: PAGES.forgetPassword,
    name: "forgetPassword",
    meta: {layout: "empty", title: 'Забыли пароль'},
    component: ForgetPassword,
  },
  {
    path: PAGES.main,
    name: "main",
    meta: {layout: "main"},
    component: HomeView,
  },
  {
    path: PAGES.financePayments,
    name: "financePayments",
    meta: {layout: "main", title: 'Платежные поручения'},
    component: FinancePayments,
  },
  {
    path: PAGES.changeMaterialsRequests,
    name: "changeMaterialsRequests",
    meta: {layout: "main", title: 'Заявки на изменение наличия материалов'},
    component: ChangeMaterialRequests,
  },
  {
    path: PAGES.quickSearch,
    name: "quickSearch",
    meta: {layout: "main", title: 'Быстрый поиск'},
    component: FastSearch,
  },
  {
    path: PAGES.payments,
    name: "payments",
    meta: {layout: "main", title: 'Ожидает оплаты'},
    component: Costs,
  },
  {
    path: PAGES.timelineGrade,
    name: "timeline_grade",
    meta: {layout: "main", padding: false, title: 'Таймлайн грейда'},
    component: TimelineGrade,
  },
  {
    path: PAGES.timelineRemont,
    name: "timeline_remont",
    meta: {layout: "main", padding: false, title: 'Таймлайн ремонта'},
    component: TimelineRemont,
  },
  {
    path: PAGES.techprojectRequests,
    name: "techprojectRequests",
    meta: {layout: "main", title: 'Заявки для проектировщиков'},
    component: TechprojectRequests,
  },
  {
    path: PAGES.compareDataOneC,
    name: "compareDataOneC",
    meta: {layout: "main", title: 'Сверка поступлений с 1C'},
    component: DataWith1C,
  },
  {
    path: PAGES.profile,
    name: "profile",
    meta: {layout: "main", title: 'Профиль'},
    component: Profile,
  },
  {
    path: PAGES.warrantyRequests,
    name: "warrantyRequests",
    meta: {layout: "main", title: 'Заявки для гарантии'},
    component: WarrantyRequests,
  },
  {
    path: PAGES.request,
    name: "request",
    meta: {layout: "main", title: 'Заявка', withParam: true},
    component: ClientsRequest,
  },
  {
    path: PAGES.providerRequests,
    name: "providerRequests",
    meta: {layout: "main", title: 'Заявки поставщикам'},
    component: ProviderRequests,
  },
  {
    path: PAGES.remontPage,
    name: "remont",
    meta: {layout: "main"},
    component: ClientRequestByRemontId,
  },
  {
    path: PAGES.handbook,
    name: "handbook",
    meta: {layout: "main", defaultTitle: 'Справочник', withParamType: true, titles: handbookTitles},
    component: Handbook,
  },
  {
    path: PAGES.agreementsJournal,
    name: "agreementsJournal",
    meta: {layout: "main", title: 'Журнал договоров'},
    component: AgreementsJournal,
  },
  {
    path: PAGES.executiveReports,
    name: "executiveReports",
    meta: {layout: "main", title: 'Отчеты для руководителей'},
    component: ExecutiveReport,
  },
  {
    path: PAGES.reportGenerator,
    name: "reportGenerator",
    meta: {layout: "main", title: 'Генератор отчетов'},
    component: ReportGenerator,
  },
  {
    path: PAGES.loginUrl,
    name: "loginUrl",
    meta: {layout: "check", routeHead: PAGES_HEADS.loginUrl},
    component: LoginUrl,
  },
  {
    path: PAGES.kanban,
    name: "kanban",
    meta: {layout: "main", padding: false, title: 'Канбан'},
    component: Kanban,
  },
  {
    path: PAGES.realization,
    name: "realization",
    meta: {layout: "main", title: 'Реализация'},
    component: RemontRealizations,
  },
  {
    path: PAGES.logging,
    name: "logging",
    meta: {layout: "main", title: 'Логирование вызовов функций'},
    component: Logging,
  },
  {
    path: "/:pathMatch(.*)*",
    name: 'not-found',
    params: {pathMatch: ['not', 'found']},
    meta: {layout: "main", title: 'Не найдено'},
    component: NotFound
  },
  // TODO delete when docEdit page for Provider Requests table will be done, it will be used as a component later, for now this is needede for dev.
  {
    path: PAGES.providerDocEdit,
    name: "providerDocEdit",
    meta: {layout: "main", title: 'Документы'},
    component: ProviderDocEdit,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  let title = to?.meta?.title ? `${to?.meta?.title} | My Space` : 'My Space | Smart System'
  try {
    if((to.meta?.withParam || to?.meta?.withParamType) && to?.params) {
      const paramsKeys = Object.keys(to?.params)
      if(paramsKeys?.length)
        title = `${to.meta?.title ? `${to.meta?.title} ` : ''}${to?.meta?.withParamType ? '' : '#'}${to?.meta?.withParamType ? to.meta?.titles[to?.params[paramsKeys[0]]] || (to.meta?.defaultTitle || '') : to?.params[paramsKeys[0]]} | My Space`
    }
  } catch (e) {}
  document.title = title
  next()
});

export default router;
export const notInitRoutes = routes.filter(item => item.meta?.layout === 'check' && !!item.meta?.routeHead)?.map(item => item.meta?.routeHead)
