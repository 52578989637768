<!-- PROVIDER_REQUESTS_REFUND_INVOICES -->

<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">

      <!-- Agreement cancel type selection -->
      <div class="form__content">
        <div class="radio_btns">
          <div>
            <q-radio v-model="formData.invoice_type" :val="1" label="Без удержания" />
          </div>
          <div>
            <q-radio v-model="formData.invoice_type" :val="2" label="Штраф" />
          </div>
        </div>
        <!-- Показываем поле для penalty_sum, если выбран вариант 2 -->
        <div v-if="formData.invoice_type === 1">
          <div class="form__content">
            <CustomSelect :options="warehouseOptions" label="Склад" :required="true" optionValueKey="warehouse_id"
              optionLabelKey="warehouse_name" :fullWidth="true" :value="this.formData.warehouse_id"
              @change="(value) => onChange('warehouse_id', value)" />
          </div>
        </div>
      </div>
      <CustomModalFooter :btn-loading="this.btnLoading" />
    </q-form>
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomSelect from "@/components/form/CustomSelect";
import { getWarehouses } from "@/views/ProviderRequests/services";

export default {
  name: "prov-req-refund-invoices",
  components: { CustomModalFooter, CustomSelect },
  props: ['data'],
  data() {
    return {
      loading: false,
      btnLoading: false,
      formData: {
        provider_request_id: this.data?.providerRequestId || '',
        invoice_type: '',
        warehouse_id: '',
        provider_request_invoice_item_id_arr: this.data?.body?.data
          ? this.data.body.data.map(item => item.provider_request_item_id)
          : '',
      },
      warehouseOptions: [],
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value;
    },
    async onSubmit() {
      this.btnLoading = true

      if (this.formData.invoice_type === 2) {
        delete this.formData.warehouse_id;
      }
      await this.data?.onSubmit(this.formData)
      this.btnLoading = false
    },
    async fetchWarehouses() {
      this.warehouseOptions = await getWarehouses();
    },
  },
  mounted() {
    this.fetchWarehouses();
  }
}
</script>

<style scoped lang="scss">
.form {
  display: grid;
  min-width: 400px;
  grid-template-columns: 12fr;
  gap: 20px;

  @media (max-width: 991px) {
    min-width: 50%;
    max-width: 100%;
    grid-template-columns: 12fr;
  }

  &__content {
    padding: 0 15px;
    margin-top: 10px;

    &_item {
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    &_item>.q-radio {
      flex: 1 1 auto;
      min-width: 100px;
    }
  }
}

.radio_btns {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  flex: 1 1 auto;
  min-width: 100px;
}
</style>