<template>
  <div class="contractors-page">
    <CustomTable title="Подрядчики" :rowsData="rowsData" :columns="contractorsHandbookColumns" :loading="isFetching"
      :show-search="true" :custom-body="true" :sticky-header="true" :showHeaderAdd="true"
      :on-add-click="this.handleAddClick">
      <template #customBody="props">
        <q-tr>
          <custom-table-cell align="center">
            {{ props.row.contractor_id }}
          </custom-table-cell>

          <custom-table-cell align="left">
            <Clickable @click="() => this.handleUpdateClick(props.row)">
              {{ props.row.contractor_name }}
            </Clickable>
          </custom-table-cell>

          <custom-table-cell align="left">
            <span v-html="props.row.director_fio"></span>
          </custom-table-cell>

          <custom-table-cell align="center" title="Документы">
            <BlockItem>
              <q-btn v-if="props.row.accession_contract_url" label="Скачать документ" color="green" size="sm"
                padding="xs" no-wrap @click="handleClickDoc(props.row.accession_contract_url)" />
              <span v-else>-</span>
            </BlockItem>
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.legal_name }}
          </custom-table-cell>

          <custom-table-cell align="center">
            {{ props.row.bin }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.address }}
          </custom-table-cell>

          <custom-table-cell align="left">
            <span v-html="props.row.city_name"></span>
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.contacts }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.iik }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.account_name }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.bik }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.contract_text }}
          </custom-table-cell>

          <custom-table-cell align="right">
            <span
              v-html="`№${props.row.accession_contract_num} <br/> от <br/> ${props.row.accession_contract_date}`"></span>
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.phone }}
          </custom-table-cell>

          <custom-table-cell align="left">
            {{ props.row.email }}
          </custom-table-cell>

          <custom-table-cell align="left">
            <span v-if="props.row.is_send_to_1c_text === 'Отправлен в 1С'">
              {{ props.row.is_send_to_1c_text }}
              <br>
              <CheckOrClose :value="props.row.is_send_to_1c" />
            </span>
            <span v-else>
              {{ props.row.is_send_to_1c_text }} <br>
            </span>
            <q-btn v-if="props.row.is_send_to_1c === 0" :loading="btnLoading[props.row.contractor_id]"
              @click="sendTo1CHandler(props.row.contractor_id, props.row.is_send_to_1c)" color="primary" size="sm"
              label="Отправить в 1С" dense />
            <span v-if="props.row.guid">
              <br>
              {{ props.row.guid }}
              <q-tooltip>guid</q-tooltip>
            </span>

          </custom-table-cell>

          <custom-table-cell align="center" vertical-align="center">
            <CustomIcon @click="() => this.handleUpdateClick(props.row)" icon="edit" />
          </custom-table-cell>

          <custom-table-cell align="center" vertical-align="center">
            <CustomIcon icon="trash" :approve-mode="true" :loading="this.loading"
              :approve="() => this.onDeleteSubmit(props.row.contractor_id)" />
          </custom-table-cell>

        </q-tr>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomIcon from "@/components/common/CustomIcon";
import CustomDropdown from "@/components/common/CustomDropdown";
import Clickable from "@/components/common/Clickable";
import CheckOrClose from "@/components/common/CheckOrClose";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
import { appActions } from "@/services/store/modules/app_module/app_actions";
import { FILE_URL, MODAL_NAMES } from "@/constants";
import { getHandbookContractors, sendTo1C, contractorsHandbookColumns, updateHandbookContractor, addHandbookContractor, deleteHandbookContractor } from "@/views/handbook/contractors/services";

export default {
  name: "HandbookContractors",
  components: { CustomTable, CustomTableCell, CustomIcon, CustomDropdown, Clickable, CheckOrClose },
  data() {
    return {
      contractorsHandbookColumns,
      rowsData: [],
      isFetching: false,
      isModalOpen: false,
      isEditMode: false,
      currentContractor: null,
      btnLoading: {},
    };
  },
  methods: {
    async getData() {
      this.isFetching = true;
      const res = await getHandbookContractors();
      this.isFetching = false;
      if (res) {
        this.rowsData = res;
      }
    },
    async handleAddClick() {
      this.$store.commit(appActions.showModal(MODAL_NAMES.Add_HANDBOOK_CONTRACTOR, {
        onSubmit: this.onAddSubmit,
        title: 'Добавить подрядчика'
      }));
    },
    async handleUpdateClick(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.Add_HANDBOOK_CONTRACTOR, {
        onSubmit: (body) => this.onUpdateSubmit(row.contractor_id, body),
        title: 'Изменить подрядчика',
        body: row
      }));
    },
    async onAddSubmit(body) {
      const res = await addHandbookContractor(body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onUpdateSubmit(contractor_id, body) {
      const res = await updateHandbookContractor(contractor_id, body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onDeleteSubmit(contractor_id) {
      this.loading = true
      const res = await deleteHandbookContractor(contractor_id)
      this.loading = false
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    handleClickDoc(link) {
      const url = `${FILE_URL}/${link}`

      window.open(url, "_blank")
    },
    async sendTo1CHandler(contractor_id, is_send_to_1c) {
      this.btnLoading = { ...this.btnLoading, [contractor_id]: true }; // Устанавливаем loading только для текущей кнопки
      const response = await sendTo1C(contractor_id, is_send_to_1c);
      this.btnLoading = { ...this.btnLoading, [contractor_id]: false };
      if (!response) return
      this.showSucceedSnackbar("Успешно отправлено в 1С");
      this.rowsData = response || this.getData()
    },
  },
  mounted() {
    this.getData();
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar };
  },
};
</script>