<template>
  <span>Документы</span>
  <div class="hr"></div>
  <div class="header">
    <div class="header__column">
      <BlockItem title="ФИО">
        {{ this.clientData.client_name }}
      </BlockItem>
      <BlockItem title="Телефон">
        {{ this.clientData.phone_number }}
      </BlockItem>
      <BlockItem title="Сумма заявки" v-if="userPositionCode !== 'PROVIDER'">
        <Amount :value="this.provData.amount" />
        <!-- {{ this.provData.amount }} -->
      </BlockItem>
    </div>
    <div class="header__column">
      <BlockItem title="Ремонт №">
        {{ this.provData.remont_id }}
      </BlockItem>
      <BlockItem title="Поставщик" v-if="userPositionCode !== 'PROVIDER'">
        {{ this.provData.provider_name }}
      </BlockItem>
      <BlockItem title="Телефон" v-if="userPositionCode !== 'PROVIDER'">
        {{ this.provData.phone }}
      </BlockItem>
      <BlockItem title="Сумма заявки" v-if="userPositionCode === 'PROVIDER'">
        <Amount :value="this.provData.amount" />
      </BlockItem>
    </div>
  </div>

  <q-form @submit="this.onSubmit" class="form">
    <div class="form__item">
      <CustomSelect :options="this.documentTypes" label="Тип документа" optionValueKey="document_type_id"
        optionLabelKey="document_type_name" :value="this.formData.document_type_id"
        @change="(value) => onChange('document_type_id', value)" />
    </div>

    <div class="form__item">
      <CustomUploader :value="this.formData?.document" :on-change="(value) => this.onChange('document', value)"
        label="Файл" />
    </div>

    <div class="footer">
      <q-btn class="footer__btn footer__btn_cancel" label="Назад" color="red" icon="close" @click="onClose"
        :disable="loading">
      </q-btn>
      <q-btn class="footer__btn footer__btn_submit" label="Сохранить" color="secondary" icon="check" :disable="loading"
        :loading="loading" @click="onSubmitSave">
      </q-btn>
    </div>
  </q-form>

  <CustomTable title="Документы" :rowsData="this.tableData" :columns="this.columns" :stickyHeader="false"
    :loading="loading" :custom-body="true" :small="true">
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          <Clickable router-link :href="`${props.row.document_url}`">
            {{ props.row.document_name }}
          </Clickable>
        </custom-table-cell>
        <custom-table-cell align="center">
          {{ props.row.document_type_name }}
        </custom-table-cell>
        <custom-table-cell align="center">
          <span v-if="userPositionCode === 'PROVIDER'">
            {{ props.row.invoice_num }}
          </span>
          <span v-else>
            {{ props.row.account_num }}
          </span>
        </custom-table-cell>
        <custom-table-cell align="center">
          {{ props.row.doc_date }}
        </custom-table-cell>
        <custom-table-cell align="right">
          <Amount :value="props.row.sum" />
        </custom-table-cell>
        <custom-table-cell align="center">
          <span v-if="userPositionCode === 'PROVIDER'">
            {{ props.row.company_name }}
          </span>
          <span v-else>
            {{ props.row.bank_pay_date }}
          </span>
        </custom-table-cell>
        <custom-table-cell align="right" v-if="userPositionCode !== 'PROVIDER'">
          <span>
            <Amount :value="props.row.bank_pay_sum" />
          </span>
        </custom-table-cell>
        <custom-table-cell align="center" v-if="userPositionCode === 'ADMIN'">
          <CustomIcon icon="trash" :approve-mode="true" :loading="this.loading"
            :approve="() => this.onDeleteSubmit(props.row.provider_request_document_id)" />
        </custom-table-cell>
      </q-tr></template>
  </CustomTable>
</template>

<script>
import BlockItem from "@/components/common/BlockItem";
import CustomSelect from "@/components/form/CustomSelect";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomUploader from "@/components/form/CustomUploader";
import { getDocsTableData, getDocsClientData, getDocsProviderInfo, getDocsTypes, DocEditTableColumns, DocEditProviderTableColumns } from './services'
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";
import Amount from "@/components/common/Amount";
import { appActionTypes } from "@/services/store/modules/app_module/app_mutation_types";


export default {
  name: "ProviderDocEdit",
  components: {
    CustomSelect,
    CustomModalFooter,
    BlockItem,
    CustomUploader,
    CustomTableCell,
    CustomIcon,
    Clickable,
    CustomTable,
    Amount,
  },
  props: {
    providerRequestId: {
      type: Number,
      required: true,
    },
    remontId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      formData: {
        document: '',
        document_type_name: '',
        document_type_id: '',
      },
      documentTypes: [],
      clientData: [],
      tableData: [],
      provData: [],
    }
  },
  methods: {
    async getDocsClientData() {
      this.loading = true;
      const res = await getDocsClientData(this.remontId);
      this.loading = false;
      if (!res) return;
      this.clientData = res[0];
    },
    async getDocsProviderInfo() {
      this.loading = true;
      const res = await getDocsProviderInfo(this.providerRequestId);
      this.loading = false;
      if (!res) return;
      this.provData = res[0];
    },
    async getDocsTableData() {
      this.loading = true;
      const res = await getDocsTableData(this.providerRequestId);
      this.loading = false;
      if (!res) return;
      this.tableData = res;
    },
    onChange(key, value) {
      this.formData[key] = value;
    },
    onSubmitSave() {
      console.log("Saving form data:", this.formData);
      // Логика для сохранения данных
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$emit("close"); // Закрытие после успешного сохранения
      }, 1000);
    },
    onClose() {
      console.log("Closing without saving");
      this.$emit("close"); // Закрытие компонента
    },
  },
  async mounted() {
    this.$store.dispatch(appActionTypes.getDocTypes)
    this.getDocsTableData()
    this.getDocsClientData()
    this.getDocsProviderInfo()
    // if provider then is_partner = -1
    const documentsResponse = await getDocsTypes(this.isPartner);
    if (documentsResponse) {
      this.documentTypes = documentsResponse.map(doc => doc)
    }
  },
  computed: {
    userPositionCode() {
      return this.$store.state.app?.userData?.position_code
    },
    columns() {
      return this.userPositionCode === 'PROVIDER'
        ? DocEditProviderTableColumns
        : DocEditTableColumns;
    },
    isPartner() { 
      if (this.userPositionCode === 'PROVIDER') {
        return 1;
      } else if (this.userPositionCode !== 'PROVIDER' &&
        this.userPositionCode !== 'CONTRACTOR' &&
        this.userPositionCode !== 'AUDITOR') {
        return 0;
      } else {
        return 2
      }
    }
  },
}
</script>

<style scoped lang="scss">
.header {
  border-radius: 15px;
  border: 2px solid #E4EAF0;
  background: #FFF;
  padding: 25px 20px 40px 20px;
  margin-top: 25px;
  display: flex;
  gap: 35px;
  position: relative;
  transition: all .3s;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    gap: 20px;
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }
}


.hr {
  display: block;
  height: 0;
  overflow: hidden;
  font-size: 0;
  border-width: 1px 0 0;
  border-top: 1px solid #E3E3E3;
  margin: 12px 0;

  &:last-child {
    border: none;
  }
}

.form {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 320px;
  gap: 15px;
  margin-top: 35px;
  margin-bottom: 35px;

  &__item {
    &_file {
      padding: 10px 7px;
      border-radius: 4px;
      border: 1px solid #ccc;
      color: #1f5ac2;

      &Label {
        margin-bottom: 3px;
        font-size: 13px;
        display: block;
        color: #3b3b3b;
      }
    }
  }

  @media (max-width: 428px) {
    min-width: 100%;
  }
}

.footer {
  display: flex;
  justify-content: flex-start;
  gap: 10px;

  &__btn {
    &.footer__btn_cancel {
      background-color: #f5f5f5;
      color: #e74c3c;
    }

    &.footer__btn_submit {
      background-color: #3498db;
      color: #fff;
    }

    &.footer__btn_disabled {
      opacity: 0.5;
    }
  }
}
</style>