import instance from "@/services/api";

export const providerRequestsFiltersAPI = {
  async getProviders(){
    return await instance()
      .get(`/provider_request/provider/`)
      .then((res) => res?.data);
  },
  async getResidents(){
    return await instance()
      .get(`/provider_request/resident/`)
      .then((res) => res?.data);
  },
  async getMaterials(){
    return await instance()
      .get(`/provider_request/material/`)
      .then((res) => res?.data);
  },
  async getMaterialTypes(){
    return await instance()
      .get(`/provider_request/material_type/`)
      .then((res) => res?.data);
  },
  async getRequestTypes(){
    return await instance()
      .get(`/provider_request/request_type/`)
      .then((res) => res?.data);
  },
  async getStatus(){
    return await instance()
      .get(`/provider_request/status/`)
      .then((res) => res?.data);
  },
  async getDaysCnt(){
    return await instance()
      .get(`/provider_request/days/`)
      .then((res) => res?.data);
  },
  async printProvReqDoc(client_request_id, ds_id) {
    return await instance()
      .get(`client_request/${client_request_id}/ds/${ds_id}/ds_print/`, {
        responseType: "arraybuffer"
      })
      .then((res) => res?.data);
  },
}
