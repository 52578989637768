<template>
    <div :class="[{ purpose: this.data?.body?.purpose }]">
        <q-form @submit="this.onSubmit" class="form">

            <div class="form__item">
                <CustomSelect :options="this.data.materialsOptions" label="Основной материал" :required="true"
                    optionValueKey="material_id" optionLabelKey="material_name" :value="this.formData.material_id || ''"
                    @change="(value) => onChange('material_id', value)" />
            </div>

            <div class="form__item">
                <CustomInput label="Цена" type="number" :value="this.formData.material_price"
                    @change_input="(value) => onChange('material_price', value)" required="true" />
            </div>

            <div class="form__item">
                <CustomInput label="Количество" type="number" :value="this.formData.material_cnt"
                    @change_input="(value) => onChange('material_cnt', value)" required="true" />
            </div>

            <div class="form__item">
                <CustomInput label="ID материала слева" type="number"
                    :value="this.formData.provider_request_material_id"
                    @change_input="(value) => onChange('provider_request_material_id', value)" required="true" />
            </div>

            <!-- Пустышка див чтобы кнопки были справа -->
            <div></div>
            <CustomModalFooter :btn-loading="this.loading" />
        </q-form>
    </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";
import { getProviderRequestInvoiceItem } from "@/views/ProviderRequests/services";

export default {
    name: "provider-requests-invoice-item",
    components: { CustomCheckbox, CustomModalFooter, CustomInput, CustomSelect },
    props: ['data'],
    data() {
        return {
            loading: false,
            formData: {
                provider_request_invoice_item_id: '',
                provider_request_id: '',
                material_id: '',
                material_cnt: '',
                material_price: '',
                param_value: '',
                provider_request_material_id: '',
            },
        }
    },
    methods: {
        onChange(key, value) {
            this.formData[key] = value
        },
        async onSubmit() {
            this.loading = true
            await this.data?.onSubmit(this.formData)
            this.loading = false
        },
        async getData(provider_request_invoice_item_id) {
            const res = await getProviderRequestInvoiceItem(provider_request_invoice_item_id);
            if (res) {
                this.formData = res
            }
        },
    },
    mounted() {
        if (!!this.data?.provider_request_invoice_item_id) {
            this.getData(this.data?.provider_request_invoice_item_id);
        }
    },
}
</script>

<style scoped lang="scss">
.form {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 6fr 6fr;
    gap: 20px;

    @media (max-width: 768px) {
        grid-template-columns: 12fr;
    }

    .form__item {
        flex: 1 1 45%;
    }
}
</style>