import { providerRequestsAPI } from "@/views/ProviderRequests/api";
import { financePaymentsFiltersAPI } from "@/views/FinancePayments/FinancePaymentsFilters/api";

export const getProviderRequests = async (filters) => {
  try {
    const res = await providerRequestsAPI.getList(filters);
    return res?.data;
  } catch (e) {}
};

export const getProviderRequestItem = async (provider_request_id) => {
  try {
    const res = await providerRequestsAPI.getProviderRequestItem(
      provider_request_id
    );
    return res?.data;
  } catch (e) {}
};

export const getProviderRequestContractors = async () => {
  try {
    const res = await providerRequestsAPI.getProviderRequestContractors();
    return res?.data;
  } catch (e) {}
};

export const getProviderRequestShippingDateHistory = async (
  provider_request_id
) => {
  try {
    const res = await providerRequestsAPI.getProviderRequestShippingDateHistory(
      provider_request_id
    );
    return res?.data;
  } catch (e) {}
};

export const updateProviderRequestContractor = async (body) => {
  try {
    const res = await providerRequestsAPI.updateProviderRequestContractor(body);
    return res?.data;
  } catch (e) {}
};

export const changeProviderRequestTypeCommon = async (body) => {
  try {
    const res = await providerRequestsAPI.changeProviderRequestTypeCommon(body);
    return res?.data;
  } catch (e) {}
};
export const changeProviderRequestProviderCommon = async (body) => {
  try {
    const res = await providerRequestsAPI.changeProviderRequestProviderCommon(
      body
    );
    return res?.data;
  } catch (e) {}
};
export const setProviderRequestBuh = async (body) => {
  try {
    const res = await providerRequestsAPI.setProviderRequestBuh(body);
    return res?.data;
  } catch (e) {}
};

export const getCommonProvidersData = async () => {
  try {
    const res = await financePaymentsFiltersAPI.getProviders();
    return res?.providers;
  } catch (e) {}
};

export const getWarehousesForRelease = async () => {
  try {
    const res = await providerRequestsAPI.getWarehousesForRelease();
    return res?.data;
  } catch (e) {}
};
export const getWarehouses = async () => {
  try {
    const res = await providerRequestsAPI.getWarehouses();
    return res?.data;
  } catch (e) {}
};
export const changeWarehouse = async (body) => {
  try {
    const res = await providerRequestsAPI.changeWarehouse(body);
    return res?.data;
  } catch (e) {}
};
export const releaseCreate = async (body) => {
  try {
    const res = await providerRequestsAPI.releaseCreate(body);
    return res?.data;
  } catch (e) {}
};

export const changeProviderRequestDateShipping = async (body) => {
  try {
    const res = await providerRequestsAPI.changeProviderRequestDateShipping(
      body
    );
    return res?.data;
  } catch (e) {}
};

export const getDocsTableData = async (provider_request_id) => {
  try {
    const res = await providerRequestsAPI.getDocsTableData(provider_request_id);
    return res?.data;
  } catch (e) {}
};
export const getDocsClientData = async (remont_id) => {
  try {
    const res = await providerRequestsAPI.getDocsClientData(remont_id);
    return res?.data;
  } catch (e) {}
};
export const getDocsProviderInfo = async (provider_request_id) => {
  try {
    const res = await providerRequestsAPI.getDocsProviderInfo(
      provider_request_id
    );
    return res?.data;
  } catch (e) {}
};
export const getDocsTypes = async (is_partner) => {
  try {
    const res = await providerRequestsAPI.getDocsTypes(is_partner);
    return res?.data;
  } catch (e) {}
};
export const getInvoiceOptions = async (provider_request_id) => {
  try {
    const res = await providerRequestsAPI.getInvoiceOptions(provider_request_id);
    return res?.data;
  } catch (e) {}
};
export const generateInvoice = async (body) => {
  try {
    const res = await providerRequestsAPI.generateInvoice(body);
    return res?.data;
  } catch (e) {}
};
export const refundInvoice = async (body) => {
  try {
    const res = await providerRequestsAPI.refundInvoice(body);
    return res?.data;
  } catch (e) {}
};
export const refundRelease = async (body) => {
  try {
    const res = await providerRequestsAPI.refundRelease(body);
    return res?.data;
  } catch (e) {}
};

export const changeQtyDelivery = async (body) => {
  try {
    const res = await providerRequestsAPI.changeQtyDelivery(body);
    return res?.data;
  } catch (e) {}
};
export const getProviderRequestInvoiceItem = async (provider_request_invoice_item_id) => {
  try {
    const res = await providerRequestsAPI.getProviderRequestInvoiceItem(provider_request_invoice_item_id);
    return res?.data;
  } catch (e) {}
};
export const getInvoiceList = async (provider_request_id) => {
  try {
    const res = await providerRequestsAPI.getInvoiceList(provider_request_id);
    return res?.data;
  } catch (e) {}
};
export const getStatusHistoryList = async (provider_request_id) => {
  try {
    const res = await providerRequestsAPI.getStatusHistoryList(provider_request_id);
    return res?.data;
  } catch (e) {}
};
export const submitInvoiceItem = async (body) => {
  try {
    const res = await providerRequestsAPI.submitInvoiceItem(body);
    return res?.data;
  } catch (e) {}
};
export const onDeleteInvoiceItem = async (provider_request_invoice_item_id) => {
  try {
    const res = await providerRequestsAPI.onDeleteInvoiceItem(provider_request_invoice_item_id);
    return res?.data;
  } catch (e) {}
};
export const onDeleteInvoice = async (provider_request_invoice_id) => {
  try {
    const res = await providerRequestsAPI.onDeleteInvoice(provider_request_invoice_id);
    return res?.data;
  } catch (e) {}
};
export const getProviderRequestStatuses = async (provider_request_id) => {
  try {
    const res = await providerRequestsAPI.getProviderRequestStatuses(provider_request_id);
    return res?.data;
  } catch (e) {}
};
export const setProviderRequestStatus = async (body) => {
  try {
    const res = await providerRequestsAPI.setProviderRequestStatus(body);
    return res?.data;
  } catch (e) {}
};
export const moveProviderReqItem = async (body) => {
  try {
    const res = await providerRequestsAPI.moveProviderReqItem(body);
    return res?.data;
  } catch (e) {}
};
export const getMaterialTypes = async () => {
  try {
    const res = await providerRequestsAPI.getMaterialTypes();
    return res?.data;
  } catch (e) {}
};
export const getReasons = async () => {
  try {
    const res = await providerRequestsAPI.getReasons();
    return res?.data;
  } catch (e) {}
};
export const getActiveMaterialsList = async () => {
  try {
    const res = await providerRequestsAPI.getActiveMaterialsList();
    return res?.data;
  } catch (e) {}
};
export const getReplacementMaterialsList = async (provider_request_item_id) => {
  try {
    const res = await providerRequestsAPI.getReplacementMaterialsList(provider_request_item_id);
    return res?.data;
  } catch (e) {}
};
export const getMaterialListClearTableData = async (body) => {
  try {
    const res = await providerRequestsAPI.getMaterialListClearTableData(body);
    return res?.data;
  } catch (e) {}
};
export const getMaterialListOnAddTableData = async (body) => {
  try {
    const res = await providerRequestsAPI.getMaterialListOnAddTableData(body);
    return res?.data;
  } catch (e) {}
};
export const getMaterialListZamena = async (body) => {
  try {
    const res = await providerRequestsAPI.getMaterialListZamena(body);
    return res?.data;
  } catch (e) {}
};
export const materialCntChangeNewReq = async (body) => {
  try {
    const res = await providerRequestsAPI.materialCntChangeNewReq(body);
    return res?.data;
  } catch (e) {}
};
export const createAdditionalReq = async (body) => {
  try {
    const res = await providerRequestsAPI.createAdditionalReq(body);
    return res?.data;
  } catch (e) {}
};

export const ProviderRequestsTableColumns = [
  {
    name: "request_id",
    label: "ID заявки",
    field: "request_id",
    sortable: true,
    headerStyle: "width: 80px",
    style: "width: 100px",
    align: "center",
  },
  {
    name: "remont_id",
    label: "ID ремонта",
    field: "remont_id",
    sortable: true,
    headerStyle: "width: 80px",
    style: "width: 100px",
    align: "center",
  },
  {
    name: "provider",
    label: "Поставщик",
    field: "provider",
    sortable: true,
    align: "center",
  },
  {
    name: "contractor",
    label: "Подрядная организация",
    field: "contractor",
    align: "center",
  },
  {
    name: "address",
    label: "Адрес доставки",
    field: "address",
    align: "center",
  },
  {
    name: "info",
    label: "Информация",
    field: "info",
    align: "center",
  },
  {
    name: "status",
    label: "Статус",
    field: "status",
    align: "center",
  },
  {
    name: "dtbn",
    label: "",
    field: "dtbn",
    align: "center",
  },
  {
    name: "dcs",
    label: "",
    field: "dcs",
    align: "center",
  },
];

export const groupActions = [
  { label: "Сменить тип заявки общий SR_PR", value: 0 },
  { label: "Сменить поставщика общий SR_PR", value: 1 },
  { label: "Сменить дату отгрузки общий SR_PR", value: 2 }, 
  { label: "Отправить накладные В 1C SR_PR", value: 3 }, 
  { label: "Сформировать накладную", value: 4 }, 
];

export const innerGroupActions = [
  { label: "Сменить дату отгрузки SR", value: 0 }, 
  { label: "Сменить тип заявки SR", value: 1 }, 
  { label: "Сменить поставщика SR", value: 2 }, 
  { label: "Сменить склад SR", value: 3 }, 
  { label: "Отгрузить со склада SR_PR*", value: 4 }, 
  { label: "Список накладных", value: 5 }, 
  { label: "История статусов", value: 6 }, 
  { label: "Сформировать накладную", value: 7 }, 
  { label: "Возврат накладных", value: 8 },
  { label: "Возврат пермещений", value: 9 }, 
];

export const DocEditTableColumns = [
  {
    name: "request_id",
    label: "Наименование",
    field: "request_id",
    sortable: true,
    align: "center",
  },
  {
    name: "remont_id",
    label: "Тип",
    field: "remont_id",
    sortable: true,
    align: "center",
  },
  {
    name: "provider",
    label: "Номер счета",
    field: "provider",
    sortable: true,
    align: "center",
  },
  {
    name: "contractor",
    label: "Дата",
    field: "contractor",
    align: "center",
  },
  {
    name: "address",
    label: "Сумма",
    field: "address",
    align: "center",
  },
  {
    name: "info",
    label: "Дата платежки с банка",
    field: "info",
    align: "center",
  },
  {
    name: "status",
    label: "Сумма платежки с банка",
    field: "status",
    align: "center",
  },
  {
    name: "dltbn",
    label: "",
    field: "dltbn",
    align: "center",
  },
];

export const DocEditProviderTableColumns = [
  {
    name: "request_id",
    label: "Наименование",
    field: "request_id",
    sortable: true,
    align: "center",
  },
  {
    name: "remont_id",
    label: "Тип",
    field: "remont_id",
    sortable: true,
    align: "center",
  },
  {
    name: "provider",
    label: "Номер счета/накладной",
    field: "provider",
    sortable: true,
    align: "center",
  },
  {
    name: "contractor",
    label: "Дата",
    field: "contractor",
    align: "center",
  },
  {
    name: "address",
    label: "Сумма",
    field: "address",
    align: "center",
  },
  {
    name: "company",
    label: "Компания",
    field: "company",
    align: "center",
  },
  // TODO узнать показывается ли тут кнопка удаления для провайдера
  // {
  //   name: "dltbn",
  //   label: "",
  //   field: "dltbn",
  //   align: "center",
  // },
];

export const NewReqTableColumns = [
  {
    name: "id",
    label: "ID",
    field: "id",
    sortable: true,
    align: "center",
  },
  {
    name: "photo",
    label: "Фото",
    field: "photo",
    sortable: true,
    align: "center",
  },
  {
    name: "material_name",
    label: "Название материала",
    field: "material_name",
    sortable: true,
    align: "center",
  },
  {
    name: "provider",
    label: "У поставщика",
    field: "provider",
    align: "center",
  },
  {
    name: "status",
    label: "Статус",
    field: "status",
    align: "center",
  },
  {
    name: "pricePerOne",
    label: "Цена за одну единицу",
    field: "pricePerOne",
    align: "center",
  },
  {
    name: "usageUnit",
    label: "Кол-во ед. расхода",
    field: "usageUnit",
    align: "center",
  },
  {
    name: "minQnt",
    label: "Кол-во в мин. ед.",
    field: "minQnt",
    align: "center",
  },
];

export const IsZamenaTableColumns = [
  {
    name: "id",
    label: "ID",
    field: "id",
    sortable: true,
    align: "center",
  },
  {
    name: "photo",
    label: "Фото",
    field: "photo",
    sortable: true,
    align: "center",
  },
  {
    name: "material_name",
    label: "Название материала",
    field: "material_name",
    sortable: true,
    align: "center",
  },
  {
    name: "provider",
    label: "У поставщика",
    field: "provider",
    align: "center",
  },
  {
    name: "status",
    label: "Статус",
    field: "status",
    align: "center",
  },
  {
    name: "pricePerOne",
    label: "Цена за одну единицу",
    field: "pricePerOne",
    align: "center",
  },
  {
    name: "usageUnit",
    label: "Кол-во ед. расхода",
    field: "usageUnit",
    align: "center",
  },
  {
    name: "minQnt",
    label: "Кол-во в мин. ед.",
    field: "minQnt",
    align: "center",
  },
  {
    name: "isZamena",
    label: "Замена",
    field: "isZamena",
    align: "center",
  },
];
