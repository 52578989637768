<template>
    <div>
        <FiltersWrapper>
            <div ref="header">
                <RemontRealizationsFilters :values="this.currentFilters" :getData="this.getData"
                    @filter_change="this.onFiltersChange" />
            </div>
        </FiltersWrapper>
        <CustomTable title="Реализации ремонтов" :rowsData="this.data" :columns="this.columns" :stickyHeader="false"
            :loading="loading" :custom-body="true" :small="true">
            <template #customBody="props">
                <q-tr>
                    <custom-table-cell align="center">
                        <span>
                            {{ props.row.remont_realisation_id }}
                            <q-tooltip>
                                ID реализации
                            </q-tooltip>
                        </span>
                        <div class="hr hr-dotted hr-0"></div>
                        <span>
                            <Clickable router-link :href="`/requests/${props.row.client_request_id}`">
                                {{ props.row.remont_id || '' }}
                            </Clickable>
                            <q-tooltip>
                                ID ремонта
                            </q-tooltip>
                        </span>
                    </custom-table-cell>
                    <custom-table-cell align="left">
                        {{ props.row.prop_fio }}
                    </custom-table-cell>
                    <custom-table-cell align="left">
                        <span v-html="`${props.row.client_agreement}`"></span>
                        <br>
                        <div class="hr hr-dotted hr-0"></div>
                        {{ props.row.remont_type }}
                    </custom-table-cell>
                    <custom-table-cell align="center">
                        {{ props.row.warranty_date }}
                    </custom-table-cell>
                    <custom-table-cell align="right">
                        <Amount :value="Math.round(props.row.sum_without_ds)" />
                    </custom-table-cell>
                    <custom-table-cell align="right">
                        <Amount :value="Math.round(props.row.sum_ds)" />
                    </custom-table-cell>
                    <custom-table-cell align="right">
                        <Amount :value="Math.round(props.row.sum_without_neustoika)" />
                    </custom-table-cell>
                    <custom-table-cell align="right">
                        <Amount :value="Math.round(props.row.sr_sum)" />
                        <span v-if="props.row.sr_share">
                            <div class="hr hr-dotted hr-0"></div>
                            <Amount :value="props.row.sr_share" :round="2" />%
                        </span>
                        <span v-else></span>
                    </custom-table-cell>

                    <custom-table-cell align="center">
                        <CustomTable :columns="this.innerColumns" :rowsData="props.row.remont_partner_json"
                            :small="true" :custom-body="true" :dense="true" flat :hideTopTitle="true" :striped="false">
                            <template #customBody="innerProps">
                                <q-tr>
                                    <custom-table-cell align="left">
                                        {{ innerProps.row.partner_type_name }}
                                    </custom-table-cell>
                                    <custom-table-cell align="left">
                                        {{ innerProps.row.contractor_name }}
                                    </custom-table-cell>
                                    <custom-table-cell align="left">
                                        <Clickable router-link
                                            :href="`/contract-journal?remont_id=${props.row.remont_id}`">
                                            {{ innerProps.row.contractor_agreement_list_id }}
                                        </Clickable>
                                        <br>
                                        <span v-if="innerProps.row.spec_name">
                                            <span v-html="`${innerProps.row.spec_name}`"></span>
                                        </span>
                                    </custom-table-cell>
                                    <custom-table-cell align="right">
                                        <Amount :value="Math.round(innerProps.row.contractor_sum)" />
                                        <div class="hr hr-dotted hr-0"></div>
                                        <Amount :value="innerProps.row.contractor_share" :round="1" />%
                                    </custom-table-cell>
                                    <custom-table-cell align="right">
                                        <Amount :value="Math.round(innerProps.row.is_payed)" />
                                    </custom-table-cell>

                                    <custom-table-cell align="right"
                                        :class="this.getBgColor(innerProps.row.partner_type_code)">
                                        <span v-if="innerProps.row.is_payed_percent">
                                            <span v-if="innerProps.row.is_payed === 0"></span>
                                            <span v-else>
                                                <Amount :value="innerProps.row.is_payed_percent" :round="1" />%
                                            </span>
                                        </span>
                                        <span v-else></span>
                                    </custom-table-cell>
                                    <custom-table-cell align="right">
                                        <Amount :value="Math.round(innerProps.row.is_not_payed)" />
                                    </custom-table-cell>
                                    <custom-table-cell align="right"
                                        :style="this.getBgColor(innerProps.row.partner_type_code)">
                                        <span v-if="innerProps.row.is_not_payed_percent">
                                            <span v-if="innerProps.row.is_not_payed === 0"></span>
                                            <span v-else>
                                                <Amount :value="innerProps.row.is_not_payed_percent" :round="1" />%
                                            </span>
                                        </span>
                                        <span v-else></span>
                                    </custom-table-cell>
                                </q-tr>
                            </template>
                        </CustomTable>
                    </custom-table-cell>

                    <custom-table-cell align="left">
                        <div class="cellRow">
                            <span v-html="`${props.row.is_send_to_1c_text}`"></span>
                            <span v-html="`${props.row.is_accept_1c_text}`"></span>
                        </div>

                        <div class="cellRow">
                            <q-btn v-if="!props.row.is_send_to_1c" :loading="this.btnLoading.sendTo1C"
                                @click="sendTo1CHandler(props.row)" color="primary" size="sm" label="Отправить в 1С" />
                            <span v-if="props.row.guid">
                                {{ props.row.guid }}
                                <q-tooltip>guid</q-tooltip>
                            </span>
                        </div>


                        <div class="cellRow">
                            <span v-if="props.row.is_accept_client_info == true">
                                Данные клиента подтверждены
                            </span>
                            <span v-else>
                                <q-btn :loading="this.btnLoading.confirmClientInfo"
                                    @click="confirmClientInfoHandler(props.row)" color="green"
                                    label="Подтвердить данные клиента" size="sm" />
                            </span>
                        </div>

                        <div class="cellRow">
                            <span v-if="props.row.is_accept_expense == true">
                                Расходы на ремонт подтверждены
                            </span>
                            <span v-else>
                                <q-btn :loading="this.btnLoading.confirmExpenses"
                                    @click="confirmExpensesHandler(props.row)" color="purple"
                                    label="Подтвердить расходы на ремонт" size="sm" />
                            </span>
                        </div>

                        <div class="cellRow">
                            <q-btn :loading="this.btnLoading.recalculate" @click="recalculateHandler(props.row)"
                                color="red" label="Пересчитать" size="sm" />
                        </div>
                    </custom-table-cell>
                </q-tr>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import {
    remontRealizationTableColumns, getRemontRealizations, detailTableColumns, sendTo1C, confirmClientInfo, confirmExpenses, recalculate
} from "./services"
import RemontRealizationsFilters from "./RemontRealizationsFilters";
import FiltersWrapper from "@/components/common/FiltersWrapper";
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import { appActions } from "@/services/store/modules/app_module/app_actions";
import Clickable from "@/components/common/Clickable";
import Amount from "@/components/common/Amount";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
export default {
    name: "RemontRealizations",
    components: {
        CustomTableCell,
        Clickable,
        FiltersWrapper,
        CustomTable,
        RemontRealizationsFilters,
        Amount,
    },
    data() {
        return {
            data: [],
            filters: {
                contractor_id: '', remont_id: '', is_accept_client_info: 2, is_accept_expense: 2, remont_type_1c: 2, is_send_to_1c: 3, is_accept_1c: 2,
            },
            currentFilters: {
                contractor_id: '', remont_id: '', is_accept_client_info: 2, is_accept_expense: 2, remont_type_1c: 2, is_send_to_1c: 3, is_accept_1c: 2,
            },
            loading: false,
            btnLoading: {
                sendTo1C: false
            },
            columns: remontRealizationTableColumns,
            innerColumns: detailTableColumns,
        }
    },
    methods: {
        async getData() {
            this.loading = true;
            const res = await getRemontRealizations(this.currentFilters);
            this.loading = false;
            if (!res) return;
            this.filters = { ...this.currentFilters }
            this.data = res;
        },
        onFiltersChange(key, value) {
            if (this.currentFilters[key] === value) return;
            this.currentFilters[key] = value;
        },
        getBgColor(partnerTypeCode) {
            if (partnerTypeCode === 'CONTRACTOR') {
                return 'partnerTypeContractor'
            } else if (partnerTypeCode === 'DESIGN_STUDIO') {
                return 'partnerTypeDesignStudio'
            }
            return ''
        },
        async sendTo1CHandler(row) {
            this.btnLoading.sendTo1C = true;
            const response = await sendTo1C(row.remont_realisation_id, row.is_send_to_1c, this.filters);
            this.btnLoading.sendTo1C = false;
            if (!response) return
            this.showSucceedSnackbar("Успешно");
            this.data = response || []
        },
        async confirmClientInfoHandler(row) {
            this.btnLoading.confirmClientInfo = true;
            const response = await confirmClientInfo(row.remont_realisation_id, this.filters);
            this.btnLoading.confirmClientInfo = false;
            if (!response) return
            this.showSucceedSnackbar("Успешно");
            this.data = response || []
        },
        async confirmExpensesHandler(row) {
            this.btnLoading.confirmExpenses = true;
            const response = await confirmExpenses(row.remont_realisation_id, this.filters);
            this.btnLoading.confirmExpenses = false;
            if (!response) return
            this.showSucceedSnackbar("Успешно");
            this.data = response || []
        },
        async recalculateHandler(row) {
            this.btnLoading.recalculate = true;
            const response = await recalculate(row.remont_realisation_id, this.filters);
            this.btnLoading.recalculate = false;
            if (!response) return
            this.showSucceedSnackbar("Успешно");
            this.data = response || []
        },
    },
    setup() {
        const { showSucceedSnackbar, showErrorSnackbar } = useCustomSnackbar();
        return { showSucceedSnackbar, showErrorSnackbar };
    },
}

</script>

<style scoped lang="scss">
.actions {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;

    &__right {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    &__print {
        background: #21830b !important;
        color: #fff;
    }

    &__warning {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;

        p {
            color: #eb9c24;
            padding: 10px;
            border-radius: 5px;
            margin: 0 0 10px;
            box-shadow: 0 0 3px #a15f20;
            font-size: 12px;
        }
    }

    &__left {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 15px;
    }

    &__delete {
        background: #9d1010 !important;
    }

    &__cancel {
        background: #c74f1c !important;
    }

    @media (max-width: 768px) {
        flex-wrap: wrap;
    }

    @media (max-width: 400px) {
        &__right {
            flex-wrap: wrap;
        }
    }
}

.hr.hr-0 {
    margin: 3px 0 !important;
}

.hr {
    display: block;
    height: 0;
    overflow: hidden;
    font-size: 0;
    border-width: 1px 0 0;
    border-top: 1px solid #E3E3E3;
    margin: 12px 0;
}

.cellRow {
    padding: 10px 0;
    border-bottom: 1px dotted rgba(0, 0, 0, .11);
    display: flex;
    flex-direction: column;
    gap: 5px;

    &>span {
        font-size: 12px;
        line-height: 14px;
    }

    &:last-child {
        border: none;
    }
}

.partnerTypeContractor {
    background-color: lightseagreen !important;
    color: #fff !important;
}

.partnerTypeDesignStudio {
    background-color: #d9edf7 !important;
}
</style>