import { API_URL, FILE_URL, LOCALSTORAGEFIELDS } from "@/constants";
import { saveAs } from "file-saver";
import instance from "@/services/api";
import Notify from 'quasar/src/plugins/Notify.js';;

export const showToast = (message, type = "negative", duration = 10000) => {
  try {
    Notify.create({
      message: message || "Ошибка",
      type: type || "negative",
      position: "bottom-left",
      timeout: duration || 10000,
      actions: [
        {
          label: "x",
          color: "white",
          handler: () => {},
        },
      ],
    });
  } catch (e) {}
};

export const showErrorToast = (message) => {
  try {
    showToast(message, "negative");
  } catch (e) {}
};

export const getFingerKey = () => {
  let finger = localStorage.getItem(LOCALSTORAGEFIELDS.fingerToken);
  if (!finger || typeof finger !== "string") {
    const fingerInstance = new Fingerprint();
    finger = fingerInstance?.get();
    if (finger)
      localStorage.setItem(LOCALSTORAGEFIELDS.fingerToken, String(finger));
  }
  return finger || "";
};

export const getLocaleDate = (date) => {
  if (!date) return "";
  return new Date(date).toLocaleDateString("ru");
};

export const getFullUrl = (url = "", api = false) => {
  try {
    return `${api ? API_URL : FILE_URL}${url}`;
  } catch (e) {}
};
export const numberWithCommas = (x, defaultValue = "", round) => {
  try {
    if (x === undefined || x === null) return defaultValue;
    let num = x;
    if (round) {
      if (/[.,]/.test(String(x))) num = Number(x).toFixed(round);
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } catch (e) {
    return defaultValue;
  }
};
export const formatPhoneNumber = (phone) => {
  if (!phone) return "";
  if (!/\d/.test(phone)) return phone;
  try {
    let phoneNumberString = phone;
    if (phone.length > 10) phoneNumberString = phoneNumberString.slice(1);
    const splited = phone.split(phoneNumberString);
    const code = `+${splited[0] || splited[1] || "7"}`;
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      const intlCode = match[1] ? "+" : "";
      return `${code} ${[
        intlCode,
        "(",
        match[2],
        ") ",
        match[3],
        "-",
        match[4],
        "-",
        match[5],
      ].join("")}`;
    }
    return phoneNumberString;
  } catch (e) {
    return phone;
  }
};
export const toCorrectPhone = (phone) => {
  if (!phone) return "";
  try {
    const numbersArray = phone.match(/\d+/g);
    if (numbersArray) {
      return numbersArray.join("");
    }
    return "";
  } catch (e) {}
};
export const getPaginationTableTitle = (
  defaultTitle = "",
  limit,
  count,
  page
) => {
  try {
    if (!limit || !count) return defaultTitle;
    let num = page * limit;
    let diff = num - limit + 1;
    if (diff <= 0) diff = 1;
    if (num > count) num = count;
    return `${defaultTitle} (${numberWithCommas(diff)}-${numberWithCommas(
      num
    )} из ${numberWithCommas(count)})`;
  } catch (e) {
    return defaultTitle;
  }
};

export const getDefaultCheckedValues = (data = [], field, idField) => {
  const selected = {};
  data?.forEach((item) => {
    try {
      if (item[field]) selected[item[idField]] = false;
    } catch (e) {}
  });
  return selected;
};
export const sliceString = (str, endIdx = 15, startIdx = 0, dots = true) => {
  try {
    if (!str || endIdx === 0) return "";
    return `${str.slice(startIdx, endIdx)}${
      str.length > endIdx + 1 && dots ? "..." : ""
    }`;
  } catch (e) {
    return "";
  }
};
export const getFileInfo = (file) => {
  if (!file) return "";
  try {
    const splited = file.split("/");
    const fullFileName = splited[splited.length - 1];
    const nameSplit = fullFileName?.split(".");
    const name = sliceString(
      nameSplit.slice(0, nameSplit?.length - 1)?.join("."),
      25,
      0,
      false
    );
    const videoTypes = ["m4v", "avi", "mpg", "mp4", "webm"];
    const imageTypes = ["jpg", "jpeg", "gif", "bmp", "png"];
    const media = [...videoTypes, ...imageTypes];
    const fileType = nameSplit[nameSplit?.length - 1];
    const fileName = `${name}.${fileType}`;
    const is_doc = !media?.some((item) => item === fileType);
    const is_image = imageTypes?.some((item) => item === fileType);
    const is_video = videoTypes?.some((item) => item === fileType);
    return { fileType, fileName, is_doc, is_video, is_image };
  } catch (e) {
    return "";
  }
};

export const downloadExcel = (res, title = "", time = "") => {
  try {
    const blob = new Blob([res], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const date = new Date().toLocaleDateString("ru");
    const timeString = time ? ` ${time}` : ""; // Добавляем время, если оно указано
    saveAs(blob, `${title} - ${date}${timeString}.xlsx`);
  } catch (e) {}
};

const decodeArrayBuffer = (data) => {
  if (!data) return;
  try {
    const uint8Array = new Uint8Array(data);
    const textDecoder = new TextDecoder("utf-8");
    return textDecoder.decode(uint8Array);
  } catch (e) {}
};

export const downloadFile = async (
  url = "",
  title = "",
  params = {},
  fileFormat = "xlsx",
  requestType,
  withDateInLabel = true
) => {
  try {
    let reqParams = { showToast: false };
    if (params) reqParams = { ...reqParams, ...params };
    if (requestType === "get") reqParams.responseType = "arraybuffer";
    await instance(true, null, { throwError: true, showToast: false })
      [requestType || "post"](url, reqParams, {
        responseType: "arraybuffer",
      })
      .then((res = {}) => {
        const decodedString = decodeArrayBuffer(res?.data);
        if (decodedString && decodedString.includes("error")) {
          return showErrorToast(decodedString);
        }
        const blob = new Blob([res.data], {
          type:
            fileFormat === "xlsx"
              ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              : "application/png",
        });
        const fileName = `${title}${
          withDateInLabel ? ` - ${new Date().toLocaleDateString("ru")}` : ""
        }`;
        saveAs(blob, `${fileName}.${fileFormat}`);
      });
  } catch (e) {
    try {
      const decodedRes = JSON.parse(decodeArrayBuffer(e.response?.data));
      if (decodedRes && decodedRes.error) {
        return showErrorToast(decodedRes.error);
      }
    } catch (e) {}
  }
};
export const searchTreeItems = (
  data,
  search,
  subMenuOption = "",
  searchOptions = []
) => {
  if (!search) return data;
  const searchItems = (data) => {
    return data
      ?.map((item) => {
        const isExist = !!searchOptions?.find((option) => {
          return item[option]?.toLowerCase()?.includes(search?.toLowerCase());
        });

        if (isExist) {
          let sub = searchItems(item[subMenuOption]);
          return {
            ...item,
            [subMenuOption]: sub,
          };
        } else if (!!item[subMenuOption]?.length) {
          const sub = searchItems(item[subMenuOption]);
          if (sub?.length)
            return {
              ...item,
              [subMenuOption]: searchItems(item[subMenuOption]),
            };
        }
        return null;
      })
      .filter((item) => !!item);
  };
  const result = searchItems(data);
  return result?.filter((item) => !!item[subMenuOption]?.length);
};
export const reportFilterCodes = {
  SELECT: "SELECT",
  INPUT: "INPUT",
  DATE: "DATE",
  MULTISELECT: "MULTISELECT",
  MULTIDATE: "MULTIDATE",
};
export const removeDangerSymbols = (str = "") => {
  if (!str) return "";
  try {
    const dangerousSymbolPattern = /[<>&'"\/\\`|!${}\[\]=+@]/g;
    return str.replaceAll(dangerousSymbolPattern, "");
  } catch (e) {
    return "";
  }
};
export const splitArrayByElement = (arr, separator) => {
  return arr.reduce(
    (acc, curr) => {
      if (curr === separator) {
        acc.push([]);
      } else {
        acc[acc.length - 1].push(curr);
      }
      return acc;
    },
    [[]]
  );
};
