<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">

      <div class="form__item">
        <CustomSelect :options="this.documentTypeOptions" label="Тип документа" :required="true" optionValueKey="value"
          optionLabelKey="label" :value="this.formData.invoice_type_id || ''"
          @change="(value) => onChange('invoice_type_id', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Номер документа" :value="this.formData.invoice_num"
          @change_input="(value) => onChange('invoice_num', value)" required="true" />
      </div>

      <div>
        <span>Дата документа:</span>
        <div class="form__item">
          <CustomDate :fullWidth="true" :value="this.formData.invoice_date"
            @change_date="(value) => onChange('invoice_date', value)" required="true" />
        </div>
      </div>

      <!-- TODO: перенести этот функционал только когда модалка открывается в Действиях в материалах заявки. Убрать когда в групповых действиях -->
      <div class="form__item" v-if="this.formData.invoice_type_id === 3">
        <CustomSelect :options="this.mappedInvoiceOptions" label="Основная накладная"
          optionValueKey="provider_request_invoice_id" optionLabelKey="label"
          :value="this.formData.main_provider_request_invoice_id || ''"
          @change="(value) => onChange('main_provider_request_invoice_id', value)" />
      </div>

      <div class="form__item" v-if="this.formData.invoice_type_id === 1 || this.formData.invoice_type_id === 2">
        <CustomCheckbox label="В том числе НДС" :value="this.formData.is_nds_param" :binary="true"
          @change="(value) => onChange('is_nds_param', value)" />
      </div>

      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";
import CustomDate from "@/components/form/CustomDate";
import { getInvoiceOptions } from "@/views/ProviderRequests/services";

export default {
  name: "provider-requests-generate-invoice",
  components: { CustomCheckbox, CustomModalFooter, CustomInput, CustomSelect, CustomDate },
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        // TODO: 
        provider_request_id: this.data.providerRequestId || this.data?.body?.data[0]?.invoice_provider_request_id || '',
        invoice_type_id: '',
        invoice_num: '',
        invoice_date: '',
        main_provider_request_invoice_id: '',
        is_nds_param: '',
        provider_request_invoice_item_id_arr: this.data?.body?.data
          ? this.data.body.data.map(item => item.provider_request_item_id)
          : '',
      },
      documentTypeOptions: [ // invoice_type_id
        { label: "Накладная", value: 1 },
        { label: "АВР", value: 2 },
        { label: "Доп. услуга к накладной", value: 3 },
      ],
      invoiceOptions: [],
      invoiceLabel: [],
    }
  },
  watch: {
    // Следим за изменением invoice_type_id
    'formData.invoice_type_id'(newValue) {
      if (newValue === 3) {
        this.fetchInvoiceOptions(this.formData.provider_request_id); // Вызываем функцию загрузки опций
      }
    },
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
    async fetchInvoiceOptions(provider_request_id) {
      const res = await getInvoiceOptions(provider_request_id);
      this.invoiceOptions = res
    },
  },
  computed: {
    mappedInvoiceOptions() {
      return this.invoiceOptions.map(option => ({
        ...option,
        label: `Накладная №${option.invoice_num} от ${option.invoice_date}`
      }));
    }
  },
}
</script>

<style scoped lang="scss">
.form {
  display: grid;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 12fr;
  }

  .form__item {
    flex: 1 1 45%;
  }
}
</style>