<template>
  <ProviderDocEdit v-if="isEditingDoc" :providerRequestId="selectedProviderRequestId" :remontId="selectedRemontId"
    @close="closeDocEdit" />
  <ProviderCreateNewReq v-if="isCreateNewReq" :clientRequestId="clientRequestId" :dsId="dsId" :remontId="remontId"
    @close="closeCreateNewReq" />
  <div v-else>
    <FiltersWrapper v-if="!this.client_request_id">
      <div ref="header">
        <ProviderRequestsFilters :values="this.currentFilters" :getData="this.getData"
          @filter_change="this.onFiltersChange" :setMaterialsOptions="this.setMaterialsOptions" />
      </div>
    </FiltersWrapper>


    <div class="actions__onTabBtns" v-if="!!this.client_request_id">
      <q-btn @click="() => this.createNewReq(this.client_request_id, this.ds_id)" label="Добавить заявку" icon="add"
        class="actions__addRequest" size="sm" color="secondary" />
      <q-btn @click="() => this.onCollapseAll()" class="actions__collapseAll" :class="{ loading: isLoading }" size="sm">
        <i class="fa-solid fa-angle-down" :class="{ rotated: !isAnyCollapsed && !isLoading }"></i>&nbsp;&nbsp;
        <span>{{ this.collapseAllLabel }}</span>
      </q-btn>
    </div>

    <CustomTable :title="computedTitle" :hideTopTitle="computedHideTitle" :rowsData="this.data"
      :columns="ProviderRequestsTableColumns" :stickyHeader="false" :loading="loading" :custom-body="true" :small="true"
      :checkbox="falseIfClientRequestId" :checkbox-value="this.allSelected" :on-all-check-click="onAllCheckClick">

      <template #top-right v-if="!this.client_request_id">
        <CustomSelect :options="this.groupActions" :label="computedLabel" optionValueKey="value" optionLabelKey="label"
          value="Групповые действия" :clearable="false" :fullWidth="true" @change="this.onGroupActionChange"
          style="width: 220px;" />
      </template>

      <template #customBody="props">
        <q-tr>
          <custom-table-cell v-if="!this.client_request_id">
            <div class="check__wrapper">
              <CustomCheckbox :value="!!this.selectedItems[props.row.provider_request_id]"
                @change="(value) => onCheckboxChange(props.row.provider_request_id, value)" />
            </div>
          </custom-table-cell>
          <custom-table-cell align="center">
            <span :class="{ 'dark-background': collapsedItems[props.row.provider_request_id] }">
              {{ props.row.provider_request_id }}
              <q-tooltip>
                ID заявки
              </q-tooltip>
            </span>
          </custom-table-cell>
          <custom-table-cell align="center">
            <span>
              <Clickable router-link :href="`/${PAGES_HEADS.remont}/${props.row.remont_id}?tab=providerRequests`">
                {{ props.row.remont_id }}
              </Clickable>
              <q-tooltip>
                ID ремонта
              </q-tooltip>
            </span>
          </custom-table-cell>
          <custom-table-cell>
            <span v-html="`${props.row.provider_name}`"></span>
            <div class="hr hr-0"></div>
            <span>Заявка №{{ props.row.provider_request_id }} от {{ props.row.date_send }}</span>
            <div class="hr hr-0"></div>
            <div>
              <!-- Гарантийная заявка -->
              <div v-if="props.row.is_grt === 1" class="badge badge-danger">
                Гарантийная заявка
              </div>

              <!-- Дополнительная заявка -->
              <div v-if="props.row.provider_request_additional_reason_id > 0" class="badge badge-success"
                :title="props.row.additional_reason_name">
                Доп. заявка
              </div>

              <!-- Со склада или от поставщика -->
              <div v-if="props.row.is_from_warehouse === 1" class="badge badge-info">
                Со склада {{ props.row.warehouse_name }}
              </div>
              <div v-else rounded class="badge badge-purple">
                От поставщика
              </div>

              <!-- Заказчик подрядчик -->
              <div v-if="props.row.provider_request_type_id === 2 && props.row.contractor_id !== null"
                class="badge badge-info badge-info-hover" :title="props.row.pay_contractor_name"
                @click="showModalProviderRequestContractorForm(props.row)">
                Заказчик подрядчик
              </div>

              <!-- Заказчик МП -->
              <div v-if="props.row.provider_request_type_id === 2 && props.row.techproject_developer_id !== null"
                class="badge badge-info" :title="props.row.pay_contractor_name">
                Заказчик МП
              </div>
            </div>
            <div class="hr hr-0"></div>
            <span> Дата ближайшей планируемой отгрузки:
              {{ props.row.date_shipping_plan_date }}
              <q-btn size="md" class="history-btn" round flat color="primary"
                @click="showModalProviderRequestShippingDateHistory(props.row.provider_request_id)">
                <i class="fa-solid fa-clock-rotate-left"></i>
              </q-btn>
            </span>
          </custom-table-cell>
          <custom-table-cell>
            <div><b>Подрядчик:</b> {{ props.row.contractor_name }}</div>
            <div><b>Прораб:</b> {{ props.row.manager_project }}</div>
            <div><b>Инициатор:</b> {{ props.row.initiator }}</div>
          </custom-table-cell>
          <custom-table-cell>
            <div><b>ЖК:</b> {{ props.row.resident_name }}</div>
            <div><b>Адрес ЖК:</b> {{ props.row.resident_address }}</div>
            <div><b>Кв:</b> {{ props.row.flat_num }}</div>
            <div><b>Город:</b> {{ props.row.city_name }}</div>
            <div><b>Контакты:</b> {{ props.row.delivery_contact }}</div> <!--  ??????? delivery_contact?-->
          </custom-table-cell>
          <custom-table-cell>
            <span v-if="props.row.invoice_info">
              <div class="backgroundBlue">
                <span v-html="props.row.invoice_info"></span>
              </div>
            </span>
          </custom-table-cell>
          <custom-table-cell>
            <span v-html="`${props.row.statuses}`"></span>
          </custom-table-cell>
          <custom-table-cell>
            <q-btn @click="() => this.toggleCollapseAndGetProviderRequestItem(props.row.provider_request_id)"
              :color="collapsedItems[props.row.provider_request_id] ? 'red' : 'green'" size="md" class="toggle-btn"
              rounded flat>
              <i class="fa-solid fa-angle-down" :class="{ rotated: collapsedItems[props.row.provider_request_id] }"></i>
            </q-btn>
          </custom-table-cell>
          <custom-table-cell>
            <span v-if="userPositionCode !== 'CONTRACTOR' && userPositionCode !== 'AUDITOR' && userPositionCode !== ''">
              <CustomIcon icon="file" color="green"
                @click="openDocEdit(props.row.provider_request_id, props.row.remont_id)"></CustomIcon>
              <q-tooltip>Документы</q-tooltip>
            </span>
          </custom-table-cell>
        </q-tr>

        <transition name="fade-collapse">
          <q-tr v-if="collapsedItems[props.row.provider_request_id]">
            <q-td colspan="20">
              <CollapseBlock
                :onChange="() => this.toggleCollapseAndGetProviderRequestItem(props.row.provider_request_id)"
                :collapsedProps="!this.collapsedItems[props.row.provider_request_id]" :controlMode="true" hideExpand>
                <CustomTable :title="`Материалы заявки №${props.row.provider_request_id}`"
                  :rowsData="Object.values(this.itemData[props.row.provider_request_id] || [])" :small="true"
                  :customHeader="true" :custom-body="true" :dense="true" flat :hideTopTitle="true"
                  :loading="innerTableLoading">
                  <template #top-right>
                    <CustomSelect :options="this.innerGroupActions" label="Выберите" optionValueKey="value"
                      optionLabelKey="label" value="Действия" :clearable="false" :fullWidth="true"
                      @change="(value) => this.onInnerGroupActionChange(value, props.row.provider_request_id, props.row.date_shipping_plan_date)"
                      style="width: 150px;" />
                    <q-btn @click="() => this.onClickMoveItem(props.row.provider_request_id)" color="primary" size="sm"
                      flat>
                      <i class="fa fa-exchange"></i>&nbsp;
                      Перенести материалы
                    </q-btn>
                  </template>
                  <template #customHeader="innerProps" сlass="innerTableHeader sticky">
                    <q-tr class="innerTableHeader">
                      <q-th rowspan="2" сlass="innerTableHeader">
                        <div class="check__wrapper">
                          <CustomCheckbox :value="this.allInnerSelectedLeft[props.row.provider_request_id]"
                            @change="(value) => onAllCheckClickInnerLeft(value, props.row.provider_request_id)" />
                        </div>
                      </q-th>
                      <q-th align="center" colspan="11" сlass="innerTableHeader">
                        Заказы
                      </q-th>
                      <q-th align="center">
                        <!-- СТАТУСЫ -->
                        <CustomSelect :options="this.innerStatuses"
                          :label="computedInnerLabel(props.row.provider_request_id, 'statuses')" value="Статус"
                          optionValueKey="provider_request_status_id" optionLabelKey="provider_request_status_name"
                          :value="this.innerStatuses.provider_request_status_id" :clearable="false" :fullWidth="true"
                          @change="(value) => this.onInnerStatusChange(props.row.provider_request_id, value)"
                          style="width: 110px;" />
                      </q-th>
                      <q-th rowspan="2">
                        <div class="check__wrapper">
                          <CustomCheckbox :value="allInnerSelectedRight[props.row.provider_request_id]"
                            @change="(value) => onAllCheckClickInnerRight(value, props.row.provider_request_id)" />
                        </div>
                      </q-th>
                      <q-th align="center" colspan="8">
                        Факт
                      </q-th>
                    </q-tr>
                    <q-tr class="sticky">
                      <q-th align="center">
                        ID
                      </q-th>
                      <q-th align="center">
                        Наименование
                      </q-th>
                      <q-th align="center">
                        Дата отгрузки
                      </q-th>
                      <q-th align="center" style="background-color: #C8E6C9;">
                        Кол-во в ед.продаж
                      </q-th>
                      <q-th align="center">
                        Кол-во в ед.отпуска
                      </q-th>
                      <q-th align="center">
                        Кол-во в ед.расх.
                      </q-th>
                      <q-th align="center">
                        В мин. кол-во
                      </q-th>
                      <q-th align="center">
                        Кол-во в упак.
                      </q-th>
                      <q-th align="center">
                        Цена
                      </q-th>
                      <q-th align="center">
                        Сумма
                      </q-th>
                      <q-th align="center">
                        Номер накладной
                      </q-th>
                      <q-th align="center">
                        Статус
                      </q-th>


                      <q-th align="center">
                        ID
                      </q-th>
                      <q-th align="center">
                        Наименование
                      </q-th>
                      <q-th align="center">
                        Кол-во в ед.продаж
                      </q-th>
                      <q-th align="center">
                        Цена
                      </q-th>
                      <q-th align="center">
                        Сумма
                      </q-th>
                      <q-th align="center">
                        <span>
                          <q-btn @click="() => this.handleAddInvoiceItem(props.row.provider_request_id, props)"
                            color="secondary" label="" size="sm" icon="add" dense round />
                        </span>
                      </q-th>
                    </q-tr>
                  </template>

                  <template #customBody="innerBodyProps">
                    <q-tr>
                      <custom-table-cell align="center">
                        <div class="check__wrapper">
                          <CustomCheckbox
                            :value="!!this.selectedInnerItemsLeft[props.row.provider_request_id]?.[innerBodyProps.row.material_id]"
                            @change="(value) => onInnerLeftCheckboxChange(props.row.provider_request_id, innerBodyProps.row.material_id, value)" />
                        </div>
                      </custom-table-cell>
                      <custom-table-cell align="center">
                        {{ innerBodyProps.row.material_id }}
                      </custom-table-cell>
                      <custom-table-cell>
                        <span v-html="`${innerBodyProps.row.material_name}`"></span>
                        <div class="hr hr-0"></div>
                        <span v-html="`${innerBodyProps.row.material_name_extra}`" style="color: brown;"></span>
                      </custom-table-cell>
                      <custom-table-cell>
                        <span v-html="`${innerBodyProps.row.date_shipping}`"></span>
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        {{ innerBodyProps.row.material_cnt }}
                        {{ innerBodyProps.row.sell_unit_name }}
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        <!-- amount -->
                        <!-- <span style="color: blue; text-decoration: underline;"
                          @click="() => this.qtyDeliveryChange(innerBodyProps.row, props.row.provider_request_id)">
                          <CustomIcon icon="edit" small /> -->
                        {{ innerBodyProps.row.delivery_cnt }}
                        {{ innerBodyProps.row.delivery_unit_name }}
                        <!-- </span> -->
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        {{ innerBodyProps.row.consumption_cnt }}
                        {{ innerBodyProps.row.consume_unit_name }}
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        {{ innerBodyProps.row.qty_atom }}
                        {{ innerBodyProps.row.atom_unit_name }}
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        {{ innerBodyProps.row.qty_pack }}
                        {{ innerBodyProps.row.pack_unit_name }}
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        <Amount :value="innerBodyProps.row.price" />
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        <Amount :value="innerBodyProps.row.price_amount" />
                      </custom-table-cell>
                      <custom-table-cell>
                        <span v-html="`${innerBodyProps.row.release_text}`"></span>
                      </custom-table-cell>
                      <custom-table-cell>
                        {{ innerBodyProps.row.provider_request_status_name }}
                      </custom-table-cell>
                      <custom-table-cell>
                        <div class="check__wrapper">
                          <CustomCheckbox
                            :value="!!this.selectedInnerItemsRight[props.row.provider_request_id]?.[innerBodyProps.row.invoice_material_id]"
                            @change="(value) => onInnerRightCheckboxChange(props.row.provider_request_id, innerBodyProps.row.invoice_material_id, value)" />
                        </div>
                      </custom-table-cell>
                      <custom-table-cell align="center">
                        {{ innerBodyProps.row.invoice_material_id }}
                      </custom-table-cell>
                      <custom-table-cell>
                        <span
                          @click="() => this.handleUpdateInvoiceItem(props.row.provider_request_id, innerBodyProps.row)">
                          <span v-html="`${innerBodyProps.row.invoice_material_name}`"
                            style="color: blue; text-decoration: underline;"></span>&nbsp;
                          <CustomIcon icon="edit" small />
                        </span>
                        <div class="hr hr-0"></div>
                        <span title="Наименование у поставщика" class="text-danger"
                          v-html="`${innerBodyProps.row.invoice_material_name_extra}`"></span><br>
                        Накладная <b>№{{ innerBodyProps.row.invoice_num }} от {{ innerBodyProps.row.invoice_date }}</b>
                        <div class="hr hr-0"></div>
                        <span style="background-color: bisque;">
                          {{ innerBodyProps.row.connected_item_id }}
                        </span>
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        {{ innerBodyProps.row.invoice_material_cnt }}
                        {{ innerBodyProps.row.invoice_unit_short_name }}
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        <Amount :value="innerBodyProps.row.invoice_material_price" />
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        <Amount :value="innerBodyProps.row.invoice_price_amount" />
                      </custom-table-cell>
                      <custom-table-cell>
                        <CustomIcon icon="trash" :approve-mode="true"
                          :loading="this.delBtnLoading[innerBodyProps.row?.provider_request_invoice_id]"
                          :approve="() => this.onDeleteInvoiceItem(innerBodyProps.row?.provider_request_invoice_item_id, props.row.provider_request_id)" />
                      </custom-table-cell>
                    </q-tr>
                  </template>

                </CustomTable>
              </CollapseBlock>
            </q-td>
          </q-tr>
        </transition>

      </template>
    </CustomTable>
  </div>
</template>

<script>
import FiltersWrapper from "@/components/common/FiltersWrapper";
import CustomTable from "@/components/common/CustomTable";
import CustomIcon from "@/components/common/CustomIcon.vue";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomSelect from "@/components/form/CustomSelect";
import Clickable from "@/components/common/Clickable";
import Amount from "@/components/common/Amount";
import CollapseBlock from "@/components/common/CollapseBlock";
import { appActions } from "@/services/store/modules/app_module/app_actions";
import { MODAL_NAMES, PAGES_HEADS } from "@/constants";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
import { ProviderRequestsTableColumns, getProviderRequests, getProviderRequestItem, updateProviderRequestContractor, groupActions, innerGroupActions, changeProviderRequestTypeCommon, setProviderRequestBuh, changeProviderRequestProviderCommon, changeProviderRequestDateShipping, changeQtyDelivery, submitInvoiceItem, onDeleteInvoiceItem, generateInvoice, refundInvoice, refundRelease, changeWarehouse, releaseCreate, getProviderRequestStatuses, setProviderRequestStatus, moveProviderReqItem } from "./services";

import ProviderRequestsFilters from "./ProviderRequestsFilters";
import ProviderDocEdit from "./providerDocEdit.vue";
import ProviderCreateNewReq from "./providerCreateNewReq.vue";

export default {
  name: "ProviderRequests",
  props: ['client_request_id'],
  components: {
    CustomTableCell,
    Clickable,
    CustomTable,
    CustomIcon,
    CustomCheckbox,
    CustomSelect,
    FiltersWrapper,
    Amount,
    CollapseBlock,
    ProviderRequestsFilters,
    ProviderDocEdit,
    ProviderCreateNewReq
  },
  data() {
    return {
      PAGES_HEADS,
      data: [],
      itemData: {},
      materialsOptions: [],
      currentFilters: {
        client_request_id: this.client_request_id || '',
        remont_status_id: 0,
        is_dirty: -1,
        request_type: 0,
        invoice_receive_org_type: 0,
        is_exist_invoice: 0,
        is_invoice_carried: 0,
        is_no_invoice: 0,
      },
      filters: {
        provider_id: '',
        date_shipping_plan_begin: new Date().toLocaleDateString("ru"),
        date_shipping_plan_end: new Date().toLocaleDateString("ru"),
        provider_request_status_id_arr: [],
      },
      collapsedItems: {},
      loading: false,
      innerTableLoading: false,
      delBtnLoading: {},
      ProviderRequestsTableColumns,
      allSelected: false,
      selectedItems: {},
      allInnerSelectedLeft: {},
      allInnerSelectedRight: {},
      selectedInnerItemsLeft: {},
      selectedInnerItemsRight: {},
      groupActions,
      innerGroupActions,
      innerStatuses: [],
      isEditingDoc: false, // Показывает, какой компонент отобразить
      selectedProviderRequestId: null,
      selectedRemontId: null,
      clientRequestId: this.client_request_id,
      dsId: this.ds_id,
      remontId: this.remont_id,
      falseIfClientRequestId: true,
      isLoading: false,
      isAnyCollapsed: true,
      someNotCollapsed: false,
      collapseAllLabel: 'Раскрыть весь список',
      isCreateNewReq: false,
    }
  },
  watch: {
    selectedItems(newValue) {
      const checkedLength = this.getSelectedLength(newValue);
      const isAllChecked = this?.data.length === checkedLength;
      if (isAllChecked && !!checkedLength) return (this.allSelected = true);
      if (checkedLength > 0) return (this.allSelected = "");
      this.allSelected = false;
    },
    selectedInnerItemsLeft: {
      deep: true,
      handler(newValue) {
        for (const providerRequestId in newValue) {
          if (typeof this.allInnerSelectedLeft !== 'object') { this.allInnerSelectedLeft = {}; }
          const checkedLength = this.getSelectedLengthInnerLeft(newValue[providerRequestId]);
          const isAllChecked = Object.keys(this.itemData[providerRequestId] || {}).length === checkedLength;
          if (isAllChecked && !!checkedLength) {
            this.allInnerSelectedLeft[providerRequestId] = true;
          }
          else if (checkedLength > 0) {
            this.allInnerSelectedLeft[providerRequestId] = '';
          } else {
            this.allInnerSelectedLeft[providerRequestId] = false;
          }
        }
      },
    },
    selectedInnerItemsRight: {
      deep: true,
      handler(newValue) {
        for (const providerRequestId in newValue) {
          if (typeof this.allInnerSelectedRight !== 'object') { this.allInnerSelectedRight = {}; }
          const checkedLength = this.getSelectedLengthInnerRight(newValue[providerRequestId]);
          const isAllChecked = Object.keys(this.itemData[providerRequestId] || {}).length === checkedLength;
          if (isAllChecked && !!checkedLength) {
            this.allInnerSelectedRight[providerRequestId] = true;
          } else if (checkedLength > 0) {
            this.allInnerSelectedRight[providerRequestId] = '';
          } else {
            this.allInnerSelectedRight[providerRequestId] = false;
          }
        }
      },
    },
  },
  created() {
    this.initializeCollapsedItems();
  },
  methods: {
    initializeCollapsedItems() {
      this.data.forEach(row => {
        this.collapsedItems[row.provider_request_id] = true; // Initialize all items as collapsed
      });
    },
    async getData() {
      this.loading = true;
      const res = await getProviderRequests(this.currentFilters);
      this.loading = false;
      if (!res) return;
      this.filters = { ...this.currentFilters }
      this.data = res;
      this.resetSelectedItems(); // TODO добавить это там где нужно (проверить)
      this.collapsedItems = {};
    },
    onFiltersChange(key, value) {
      if (this.currentFilters[key] === value) return;
      this.currentFilters[key] = value;
    },
    async loadProviderRequestItem(provider_request_id, apiRes = null) {
      this.innerTableLoading = true;
      const res = apiRes || await getProviderRequestItem(provider_request_id);
      this.innerTableLoading = false;
      if (!res) return null;
      // Обновляем itemData
      this.itemData = {
        ...this.itemData,
        [provider_request_id]: res,
      };
      // Выполняем дополнительные действия
      this.initializeAllInnerCheckStates();
      this.fetchInnerStatuses(provider_request_id);
      return res;
    },
    async toggleCollapseAndGetProviderRequestItem(provider_request_id) {
      this.collapsedItems[provider_request_id] = !this.collapsedItems[provider_request_id];

      if (!this.collapsedItems[provider_request_id]) {
        // Логика для действия при закрытии
        this.onCollapse(provider_request_id); // Вызов функции для обработки закрытия
        this.collapseAllLabel = "Раскрыть весь список";
        this.isAnyCollapsed = true; // Все теперь закрыты
        return;
      }

      // Логика для действия при открытии
      this.innerTableLoading = true;
      await this.loadProviderRequestItem(provider_request_id);
    },
    async onCollapseAll() {
      this.isLoading = true; // Начало загрузки

      // Initialize collapsedItems if not already initialized
      this.data.forEach(row => {
        const providerRequestId = row.provider_request_id;
        if (this.collapsedItems[providerRequestId] === undefined) {
          this.collapsedItems[providerRequestId] = false;
        }
      });
      // Определяем, нужно ли открыть все или закрыть все
      const isAnyCollapsed = Object.values(this.collapsedItems).some(collapsed => !collapsed);

      if (isAnyCollapsed) {
        // Есть хотя бы одна закрытая таблица — открываем все
        const promises = this.data.map(async row => {
          const providerRequestId = row.provider_request_id;
          if (!this.collapsedItems[providerRequestId]) {
            this.collapsedItems[providerRequestId] = true;
            await this.loadProviderRequestItem(providerRequestId); // Загружаем данные асинхронно
          }
        });

        await Promise.all(promises); // Дожидаемся загрузки всех данных
        this.showSucceedSnackbar("Весь список успешно раскрыт");
        this.collapseAllLabel = "Скрыть весь список";
        this.isAnyCollapsed = false; // Все теперь открыты
      } else {
        // Все таблицы открыты — закрываем все
        this.collapsedItems = Object.fromEntries(
          this.data.map(row => [row.provider_request_id, false])
        );
        this.data.forEach(row => {
          this.onCollapse(row.provider_request_id); // Вызываем логику для закрытия
        });
        this.resetSelectedItems(); // Сбрасываем выделенные элементы
        this.showSucceedSnackbar("Весь список успешно закрыт");
        this.collapseAllLabel = "Раскрыть весь список";
        this.isAnyCollapsed = true; // Все теперь закрыты
      }
      this.isLoading = false;
    },
    onCollapse(provider_request_id) {
      this.onAllCheckClickInnerRight(false, provider_request_id);
      this.onAllCheckClickInnerLeft(false, provider_request_id);
    },
    async onClickMoveItem(provider_request_id) {
      const res = await moveProviderReqItem(provider_request_id);
      if (!res) return;
      await this.loadProviderRequestItem(provider_request_id);
      this.showSucceedSnackbar();
    },
    showModalProviderRequestContractorForm(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_CONTRACTOR_FORM, {
        onSubmit: (body) => this.onUpdateSubmit(body),
        title: 'Изменение подрядчика',
        body: row
      }));
    },
    showModalProviderRequestShippingDateHistory(provider_request_id) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_SHIPPING_DATE_HISTORY, {
        title: 'История изменений даты отгрузки',
        provider_request_id: provider_request_id
      }));
    },
    onShowInvoiceListModal(providerRequestId) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_INVOICE_LIST, {
        title: `Накладные заявки №${providerRequestId}`,
        providerRequestId: providerRequestId
      }));
    },
    onShowStatusHistory(providerRequestId) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_STATUS_HISTORY, {
        title: `История изменений заявки №${providerRequestId}`,
        providerRequestId: providerRequestId
      }));
    },
    showModalProviderForm(row, providerRequestId) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_PROVIDER_FORM, {
        onSubmit: (body) => this.onUpdateProviderSubmit(body, providerRequestId),
        title: 'Изменить поставщика в заявке',
        body: row,
        providerRequestId: providerRequestId,
      }));
    },
    showModalShippingDateChange(row, providerRequestId) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_SHIPPING_DATE_CHANGE, {
        onSubmit: (body) => this.onUpdateProviderShippingDate(body),
        title: 'Изменение даты плановой отгрузки',
        body: row,
        providerRequestId: providerRequestId,
      }));
    },
    async onUpdateSubmit(body) {
      const res = await updateProviderRequestContractor(body)
      if (!res) return
      this.getData()
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onUpdateProviderSubmit(body, providerRequestId) {
      const res = await changeProviderRequestProviderCommon(body)
      if (!res) return
      if (!!providerRequestId) {
        this.loadProviderRequestItem(providerRequestId);
      } else {
        this.getData()
      }
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onUpdateProviderShippingDate(body, providerRequestId) {
      const res = await changeProviderRequestDateShipping(body)
      if (!res) return
      if (!!providerRequestId) {
        this.loadProviderRequestItem(providerRequestId);
      } else {
        this.getData()
      }
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    getSelectedLength(selectedItems) {
      if (selectedItems)
        return Object.keys(selectedItems).filter(
          (item) => !!selectedItems[item]
        ).length;
      return Object.keys(this.selectedItems).filter(
        (item) => !!this.selectedItems[item]
      ).length;
    },
    getSelectedLengthInnerLeft(selectedInnerItemsLeft) {
      if (selectedInnerItemsLeft)
        return Object.keys(selectedInnerItemsLeft).filter(
          (item) => !!selectedInnerItemsLeft[item]
        ).length;
      return Object.keys(this.selectedInnerItemsLeft).filter(
        (item) => !!this.selectedInnerItemsLeft[item]
      ).length;
    },
    getSelectedLengthInnerRight(selectedInnerItemsRight) {
      if (selectedInnerItemsRight)
        return Object.keys(selectedInnerItemsRight).filter(
          (item) => !!selectedInnerItemsRight[item]
        ).length;
      return Object.keys(this.selectedInnerItemsRight).filter(
        (item) => !!this.selectedInnerItemsRight[item]
      ).length;
    },
    onAllCheckClick(value) {
      this.allSelected = value;
      this.selectedItems = this.data.reduce((acc, item) => {
        acc[item.provider_request_id] = value;
        return acc;
      }, {});
    },
    initializeInnerCheckState(side) {
      const allInnerSelectedKey = `allInnerSelected${side}`;
      const selectedInnerItemsKey = `selectedInnerItems${side}`;
      for (const providerRequestId in this.itemData) {
        const items = this.itemData[providerRequestId] || [];
        const selectedItems = this[selectedInnerItemsKey][providerRequestId] || {};
        const checkedLength = items.filter(
          (item) => selectedItems[item.material_id] === true
        ).length;
        if (checkedLength === items.length && items.length > 0) {
          this[allInnerSelectedKey][providerRequestId] = true;
        } else if (checkedLength > 0) {
          this[allInnerSelectedKey][providerRequestId] = '';
        } else {
          this[allInnerSelectedKey][providerRequestId] = false;
        }
      }
    },
    initializeAllInnerCheckStates() {
      this.initializeInnerCheckState('Left');
      this.initializeInnerCheckState('Right');
    },
    onAllCheckClickInnerLeft(value, providerRequestId) {
      if (typeof this.selectedInnerItemsLeft[providerRequestId] !== 'object' || this.selectedInnerItemsLeft[providerRequestId] === null) {
        this.selectedInnerItemsLeft[providerRequestId] = {};
      }
      const items = this.itemData[providerRequestId] || [];
      items.forEach((item) => {
        this.$nextTick(() => { this.selectedInnerItemsLeft[providerRequestId][item.material_id] = value; });
      });
      this.allInnerSelectedLeft[providerRequestId] = value;
    },
    onAllCheckClickInnerRight(value, providerRequestId) {
      if (typeof this.selectedInnerItemsRight[providerRequestId] !== 'object' || this.selectedInnerItemsRight[providerRequestId] === null) {
        this.selectedInnerItemsRight[providerRequestId] = {};
      }
      const items = this.itemData[providerRequestId] || [];
      items.forEach((item) => { this.$nextTick(() => { this.selectedInnerItemsRight[providerRequestId][item.invoice_material_id] = value; }); });
      this.allInnerSelectedRight[providerRequestId] = value;
    },
    onCheckboxChange(provider_request_id, value) {
      this.selectedItems = { ...this.selectedItems, [provider_request_id]: value };
    },
    onInnerLeftCheckboxChange(providerRequestId, materialId, value) {
      if (!this.selectedInnerItemsLeft[providerRequestId] || typeof this.selectedInnerItemsLeft[providerRequestId] !== 'object') {
        this.selectedInnerItemsLeft[providerRequestId] = {};
      }
      this.selectedInnerItemsLeft[providerRequestId][materialId] = value;
      const items = this.itemData[providerRequestId] || [];
      const allSelected = items.every((item) => this.selectedInnerItemsLeft[providerRequestId][item.material_id] === true);
      const someSelected = items.some((item) => this.selectedInnerItemsLeft[providerRequestId][item.material_id] === true);
      if (allSelected) { this.allInnerSelectedLeft[providerRequestId] = true; }
      else if (someSelected) { this.allInnerSelectedLeft[providerRequestId] = ''; }
      else { this.allInnerSelectedLeft[providerRequestId] = false; }
    },
    onInnerRightCheckboxChange(providerRequestId, invoiceMaterialId, value) {
      if (!this.selectedInnerItemsRight[providerRequestId] || typeof this.selectedInnerItemsRight[providerRequestId] !== 'object') {
        this.selectedInnerItemsRight[providerRequestId] = {};
      }
      this.selectedInnerItemsRight[providerRequestId][invoiceMaterialId] = value;
      const items = this.itemData[providerRequestId] || [];
      const allSelected = items.every((item) => this.selectedInnerItemsRight[providerRequestId][item.invoice_material_id] === true);
      const someSelected = items.some((item) => this.selectedInnerItemsRight[providerRequestId][item.invoice_material_id] === true);

      if (allSelected) { this.allInnerSelectedRight[providerRequestId] = true; }
      else if (someSelected) { this.allInnerSelectedRight[providerRequestId] = ''; }
      else { this.allInnerSelectedRight[providerRequestId] = false; }
    },
    resetSelectedItems() {
      this.selectedItems = {};
      this.selectedInnerItemsLeft = {};
      this.selectedInnerItemsRight = {};
    },
    // Групповые действия:
    getSelectedRows() {
      return this.data.filter((row) => this.selectedItems[row.provider_request_id]);
    },
    getInnerLeftSelectedRows(providerRequestId) {
      const items = this.itemData[providerRequestId] || [];
      const selectedItems = this.selectedInnerItemsLeft[providerRequestId] || {};
      return items.filter((row) => selectedItems[row.material_id] === true);
    },
    getInnerRightSelectedRows(providerRequestId, isInner) {
      if (!!isInner) {
        // Возвращаем данные только для указанного `providerRequestId`
        const items = this.itemData[providerRequestId] || [];
        const selectedItems = this.selectedInnerItemsRight[providerRequestId] || {};
        return items.filter(row => selectedItems[row.invoice_material_id] === true);
      }

      // Общий режим: возвращаем данные для всех `providerRequestId`
      const selectedProviderRequestIds = Object.keys(this.selectedInnerItemsRight).filter(id => {
        const innerSelected = this.selectedInnerItemsRight[id];
        return Object.values(innerSelected).some(value => value === true);
      });

      return selectedProviderRequestIds.flatMap(providerRequestId => {
        const items = this.itemData[providerRequestId] || [];
        const selectedItems = this.selectedInnerItemsRight[providerRequestId] || {};
        return items.filter(row => selectedItems[row.invoice_material_id] === true);
      });
    },

    async onInnerStatusChange(providerRequestId, provider_request_status_id) {
      const leftSelectedRows = this.getInnerLeftSelectedRows(providerRequestId);
      if (!leftSelectedRows.length) {
        this.$q.notify({
          color: 'warning',
          message: 'Выберите хотя бы одну внутреннюю позицию слева, для выполнения действия',
        });
        return;
      }
      const isConfirmed = confirm("Вы уверены, что хотите изменить тип статуса?");
      if (!isConfirmed) {
        return; // Если пользователь отказался, выходим из функции
      }
      const selectedItemsArray = leftSelectedRows.map((row) => row.provider_request_item_id);
      const body = {
        provider_request_id: providerRequestId,
        provider_request_item_id_arr: selectedItemsArray,
        provider_request_status_id: provider_request_status_id
      }
      const res = await setProviderRequestStatus(body)
      if (!res) return
      this.loadProviderRequestItem(providerRequestId, res);
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onGroupActionChange(value) {
      switch (value) {
        case 0: // "Сменить тип заявки общий SR_PR"
        case 1: // "Сменить поставщика общий SR_PR"
        case 2: // "Сменить дату отгрузки общий SR_PR"
        case 3: // "Отправить накладные в 1C SR_PR"
          const selectedRows = this.getSelectedRows();
          if (!selectedRows.length) {
            this.$q.notify({
              color: 'warning',
              message: 'Выберите хотя бы одну строку для выполнения действия',
            });
            return;
          }
          const body = {
            data: selectedRows,
          };
          switch (value) {
            case 0:
              await this.onClickChangeRequestType(body);
              break;
            case 1:
              await this.onClickChangeProvider(body);
              break;
            case 2:
              await this.onClickChangeShippingDate(body);
              break;
            case 3:
              await this.onSendInvoicesTo1C(body);
              break;
          }
          break;

        case 4: // "Сформировать накладную"
          const selectedProviderRequestIds = this.getSelectedRows().map(row => row.provider_request_id);
          const innerSelectedRowsRight = this.getInnerRightSelectedRows(selectedProviderRequestIds);

          if (!innerSelectedRowsRight.length) {
            this.$q.notify({
              color: 'warning',
              message: 'Выберите хотя бы одну внутреннюю позицию справа, для выполнения действия',
            });
            return;
          }
          const innerRightBody = {
            data: innerSelectedRowsRight,
          };
          await this.onGenerateInvoice(innerRightBody);

          break;

        default:
          console.error("Неизвестное действие: ", value);
      }
    },

    async onInnerGroupActionChange(value, providerRequestId, date_shipping_plan) {
      // Получаем данные только для конкретного provider_request_id
      const itemDataBody = this.itemData[providerRequestId] || [];
      let body;
      switch (value) {
        // Левые чб
        case 0: // "Сменить дату отгрузки SR"
        case 1: // "Сменить тип заявки SR"
        case 2: // "Сменить поставщика SR"
        case 9: // "Возврат перемещений"
          const leftSelectedRows = this.getInnerLeftSelectedRows(providerRequestId);
          if (!leftSelectedRows.length) {
            this.$q.notify({
              color: 'warning',
              message: 'Выберите хотя бы одну внутреннюю позицию слева, для выполнения действия',
            });
            return;
          }
          body = { data: leftSelectedRows };
          if (value === 0) {
            await this.onClickChangeShippingDate(body, providerRequestId);
          } else if (value === 1) {
            await this.onClickChangeRequestType(body, providerRequestId);
          } else if (value === 2) {
            await this.onClickChangeProvider(body, providerRequestId);
          }
          else if (value === 9) {
            await this.onRefundRelease(body, providerRequestId);
          }
          break;

        // Правые чб
        case 7: // "Сформировать накладную"
        case 8: // "Возврат накладных"
          const rightSelectedRows = this.getInnerRightSelectedRows(providerRequestId, 'isInner');

          if (!rightSelectedRows.length) {
            this.$q.notify({
              color: 'warning',
              message: 'Выберите хотя бы одну внутреннюю позицию справа, для выполнения действия',
            });
            return;
          }
          body = { data: rightSelectedRows };
          if (value === 7) {
            await this.onGenerateInvoice(body, providerRequestId);
          } else if (value === 8) {
            await this.onRefundInvoices(body, providerRequestId);
          }
          break;

        // Без чб:
        case 3: // "Сменить склад SR"
          await this.onChangeWarehouseSR(providerRequestId);
          break;

        case 4: // "Отгрузить со склада SR_PR*"
          await this.onShipFromWarehouse(date_shipping_plan, providerRequestId);
          break;

        case 5: // "Список накладных"
          await this.onShowInvoiceListModal(providerRequestId);
          break;

        case 6: // "История статусов"
          await this.onShowStatusHistory(providerRequestId);
          break;

        default:
          console.error("Неизвестное действие: ", value);
      }
    },

    async onClickChangeRequestType(body, providerRequestId) {
      const isConfirmed = confirm("Вы уверены, что хотите изменить тип заявки?");
      if (!isConfirmed) {
        return;
      }
      const key = body.data[0].provider_request_item_id ? 'provider_request_item_id_arr' : 'provider_request_id_arr';
      const payload = {
        [key]: body.data.map(row => row[key === 'provider_request_item_id_arr' ? 'provider_request_item_id' : 'provider_request_id']),
        toggle_is_from_warehouse: 1,
      };
      const res = await changeProviderRequestTypeCommon(payload);
      if (!res) return;
      if (!!body.data[0].provider_request_item_id) {
        this.loadProviderRequestItem(providerRequestId);
      } else {
        this.getData()
      }
      this.showSucceedSnackbar();
    },
    async onClickChangeProvider(body, providerRequestId) {
      const key = body.data[0].provider_request_item_id ? 'provider_request_item_id_arr' : 'provider_request_id_arr';
      const payload = {
        [key]: body.data.map(row => row[key === 'provider_request_item_id_arr' ? 'provider_request_item_id' : 'provider_request_id']),
        toggle_is_from_warehouse: 1,
      };
      return this.showModalProviderForm(payload, providerRequestId)
    },
    async onClickChangeShippingDate(body, providerRequestId) {
      const key = body.data[0].provider_request_item_id ? 'provider_request_item_id_arr' : 'provider_request_id_arr';
      const payload = {
        [key]: body.data.map(row => row[key === 'provider_request_item_id_arr' ? 'provider_request_item_id' : 'provider_request_id']),
        toggle_is_from_warehouse: 1,
      };
      return this.showModalShippingDateChange(payload, providerRequestId)
    },
    async onSendInvoicesTo1C(body) {
      const payload = {
        provider_request_id_arr: body.data.map(row => row.provider_request_id),
        toggle_is_from_warehouse: 1,
      };
      const res = await setProviderRequestBuh(payload);
      if (!res) return;
      this.getData()
      this.showSucceedSnackbar();
    },
    async onGenerateInvoice(body, providerRequestId) {
      const title = providerRequestId ? `Накладная к заявке №${providerRequestId}` : `Накладная к заявке`;
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_GENERATE_INVOICE, {
        onSubmit: (body) => this.onGenerateInvoiceSubmit(body),
        title: title,
        body: body,
        providerRequestId: providerRequestId,
      }));
    },
    async onGenerateInvoiceSubmit(body) {
      const res = await generateInvoice(body)
      if (!res) return
      this.getData()
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onRefundInvoices(body, providerRequestId) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_REFUND_INVOICES, {
        onSubmit: (body) => this.onRefundInvoicesSubmit(body, providerRequestId),
        title: `Возврат накладных`,
        body: body,
        providerRequestId: providerRequestId
      }));
    },
    async onRefundInvoicesSubmit(body, providerRequestId) {
      const res = await refundInvoice(body)
      if (!res) return
      if (!!providerRequestId) {
        this.loadProviderRequestItem(providerRequestId);
      } else {
        this.getData()
      }
      this.$store.commit(appActions.closeModal());
    },
    async onRefundRelease(body, providerRequestId) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_REFUND_RELEASE, {
        onSubmit: (body) => this.onRefundReleaseSubmit(body, providerRequestId),
        title: `Возврат накладных`,
        body: body,
        providerRequestId: providerRequestId
      }));
    },
    async onRefundReleaseSubmit(body, providerRequestId) {
      const res = await refundRelease(body)
      if (!res) return
      if (!!providerRequestId) {
        this.loadProviderRequestItem(providerRequestId);
      } else {
        this.getData()
      }
      this.$store.commit(appActions.closeModal());
    },
    async onChangeWarehouseSR(providerRequestId) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_CHANGE_WAREHOUSE, {
        onSubmit: (body) => this.onChangeWarehouseSubmit(body, providerRequestId),
        title: `Изменить склад в заявке`,
        provider_request_id: providerRequestId
      }));
    },
    async onChangeWarehouseSubmit(body, providerRequestId) {
      const res = await changeWarehouse(body)
      if (!res) return
      if (!!providerRequestId) {
        this.loadProviderRequestItem(providerRequestId);
      } else {
        this.getData()
      }
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onShipFromWarehouse(date_shipping_plan, providerRequestId) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_SHIP_FROM_WAREHOUSE, {
        onSubmit: (body) => this.onShipFromWarehouseSubmit(body, providerRequestId),
        title: `Создание отгрузки к заявке №${providerRequestId}`,
        date_shipping_plan: date_shipping_plan,
        provider_request_id: providerRequestId
      }));
    },
    async onShipFromWarehouseSubmit(body, providerRequestId) {
      const res = await releaseCreate(body)
      if (!res) return
      if (!!providerRequestId) {
        this.loadProviderRequestItem(providerRequestId);
      } else {
        this.getData()
      }
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    openDocEdit(providerRequestId, remontId) {
      this.selectedProviderRequestId = providerRequestId;
      this.selectedRemontId = remontId;
      this.isEditingDoc = true;
    },
    closeDocEdit() {
      this.isEditingDoc = false;
    },
    createNewReq() {
      this.isCreateNewReq = true;
    },
    closeCreateNewReq() {
      this.isCreateNewReq = false;
    },
    // TODO уточнить нужно ли оставить? Пока что скрываю
    // qtyDeliveryChange(row, provider_request_id) {
    //   const body = {
    //     provider_request_item_id: row.provider_request_item_id,
    //     provider_request_id: row.provider_request_id,
    //     row: row,
    //     provider_request_id: provider_request_id
    //   };
    //   this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_QTY_DELIVERY_CHNG, {
    //     onSubmit: (body) => this.onQtyDeliveryChange(body),
    //     title: `Заявка №${body.provider_request_id}`,
    //     body: body,
    //   }));
    // },
    // async onQtyDeliveryChange(body) {
    //   const res = await changeQtyDelivery(body)
    //   if (!res) return
    //   this.innerTableLoading = true;
    //   const itemRes = await getProviderRequestItem(body.provider_request_id)
    //   this.innerTableLoading = false;
    //   if (itemRes) {
    //     this.itemData = itemRes
    //   }
    //   this.showSucceedSnackbar()
    //   this.$store.commit(appActions.closeModal());
    // },

    setMaterialsOptions(materials) {
      this.materialsOptions = materials
    },
    async handleAddInvoiceItem(provider_request_id) {
      if (!this.materialsOptions || this.materialsOptions.length === 0) {
        this.showErrorSnackbar("Пожалуйста, дождитесь загрузки списка материалов.");
        return;
      }
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_INVOICE_ITEM, {
        onSubmit: (body) => this.handleInvoiceItemSubmit(provider_request_id, body),
        title: `Заявка №${provider_request_id}`,
        materialsOptions: this.materialsOptions,
        provider_request_id: provider_request_id,
      }));
    },
    async handleUpdateInvoiceItem(provider_request_id, row) {
      if (!this.materialsOptions || this.materialsOptions.length === 0) {
        this.showErrorSnackbar("Пожалуйста, дождитесь загрузки списка материалов.");
        return;
      }
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_INVOICE_ITEM, {
        onSubmit: this.handleInvoiceItemSubmit(provider_request_id),
        title: `Заявка №${provider_request_id}`,
        materialsOptions: this.materialsOptions,
        body: row,
        provider_request_invoice_item_id: row.provider_request_invoice_item_id
      }));
    },
    async handleInvoiceItemSubmit(providerRequestId, body) {
      const res = await submitInvoiceItem(body)
      if (!res) return
      if (!!providerRequestId) {
        this.loadProviderRequestItem(providerRequestId);
      } else {
        this.getData()
      }
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onDeleteInvoiceItem(provider_request_invoice_item_id, providerRequestId) {
      this.delBtnLoading = { ...this.delBtnLoading, [provider_request_invoice_item_id]: true }; // Устанавливаем loading только для текущей кнопки
      const res = await onDeleteInvoiceItem(provider_request_invoice_item_id);
      this.btnLoading = { ...this.delBtnLoading, [provider_request_invoice_item_id]: false };
      if (!res) return
      if (!!providerRequestId) {
        this.loadProviderRequestItem(providerRequestId);
      } else {
        this.getData()
      }
      this.showSucceedSnackbar()
    },
    async fetchInnerStatuses(provider_request_id) {
      this.innerStatuses = await getProviderRequestStatuses(provider_request_id);
    },
    computedInnerLabel(providerRequestId, statuses) {
      const selectedInnerLeft = this.selectedInnerItemsLeft[providerRequestId] || {};
      const selectedInnerRight = this.selectedInnerItemsRight[providerRequestId] || {};

      const hasInnerLeftSelected = Object.values(selectedInnerLeft).some(Boolean);
      const hasInnerRightSelected = Object.values(selectedInnerRight).some(Boolean);
      if (statuses) { return hasInnerLeftSelected ? "Нажмите" : "Выберите"; }
      return hasInnerLeftSelected || hasInnerRightSelected ? "Нажмите для выбора действия" : "Выберите позиции";
    },
  },
  setup() {
    const { showSucceedSnackbar, showErrorSnackbar } = useCustomSnackbar()
    return { showSucceedSnackbar, showErrorSnackbar }
  },
  computed: {
    userPositionCode() {
      return this.$store.state.app?.userData?.position_code
    },
    computedLabel() {
      // Проверяем, есть ли данные в selectedItems
      const hasSelected = Object.values(this.selectedItems).some((value) => value === true);
      return hasSelected ? "Нажмите для выбора действия" : 'Выберите позиции';
    },
    computedTitle() {
      return this.client_request_id ? false : 'Заявки поставщикам';
    },
    computedHideTitle() {
      return this.client_request_id ? true : false;
    },
  },
  mounted() {
    if (!!this.client_request_id) {
      this.getData()
      this.falseIfClientRequestId = false
    }
    this.initializeAllInnerCheckStates();
  },

}

</script>

<style scoped lang="scss">
.hr.hr-0 {
  margin: 3px 0 !important;
}

.hr {
  display: block;
  height: 0;
  overflow: hidden;
  font-size: 0;
  border-width: 1px 0 0;
  border-top: 1px solid #E3E3E3;
  margin: 12px 0;

  &:last-child {
    border: none;
  }
}

.innerTableHeader {
  border: 1px solid rgba(0, 0, 0, 0.12) !important
}

.q-table thead.innerTableHeader,
.q-table tr.innerTableHeader,
.q-table th.innerTableHeader,
.q-table td.innerTableHeader {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.badge {
  padding: 5px 10px;
  border-radius: 20px;
  /* Закругление */
  font-size: 10px;
  color: #fff;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Для эффекта hover */
}

.text-danger {
  color: #dc3545;
}

.badge-danger {
  background-color: #dc3545;
}

.badge-success {
  background-color: #28a745;
}

.badge-info {
  background-color: #17a2b8;
}

.badge-purple {
  background-color: #6f42c1;
}

.badge-info-hover:hover {
  background-color: #138496;
  /* Новый цвет при наведении */
  transform: scale(1.05);
  /* Увеличение при наведении */
  cursor: pointer;
  /* Указывает, что элемент кликабелен */
}

.history-btn {
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Плавные эффекты */
}

.history-btn:hover {
  background-color: #4387e0;
  color: white !important;
  /* Цвет при наведении */
  transform: scale(1.1);
  /* Легкое увеличение кнопки */
  cursor: pointer;
  /* Указатель */
}

.toggle-btn {
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Плавные эффекты */
}

.toggle-btn:hover {
  background-color: #138496;
  color: white;
  /* Цвет при наведении */
  transform: scale(1.1);
  /* Легкое увеличение кнопки */
  cursor: pointer;
  /* Указатель */
}

.toggle-btn i {
  transition: transform 0.2s ease;
  /* Плавное вращение */
}

.toggle-btn i.rotated {
  transform: rotate(180deg);
  /* Вращение вниз */
}

.actions__collapseAll.loading {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.actions__collapseAll i {
  transition: transform 0.3s ease;
  font-size: 1.5em;
  /* Увеличение размера иконки */
  vertical-align: middle;
  /* Выравнивание по вертикали */
}

.actions__collapseAll i.rotated {
  transform: rotate(180deg);
}

.actions__addRequest i {
  font-size: 1.5em;
  /* Увеличение размера иконки для другой кнопки */
  vertical-align: middle;
  /* Выравнивание по вертикали */
}

.fade-collapse-enter-active,
.fade-collapse-leave-active {
  transition: all 0.3s ease;
  overflow: hidden;
}

.fade-collapse-enter-from,
.fade-collapse-leave-to {
  max-height: 0;
  opacity: 0;
}

.fade-collapse-enter-to,
.fade-collapse-leave-from {
  opacity: 1;
}

.sticky {
  position: sticky !important;
  z-index: 1;
  top: 0
}

.backgroundBlue {
  /* // Голубой */
  background-color: #E3F2FD;
  border-radius: 8px;
  padding: 5px;
}

.dark-background {
  background-color: rgba(0, 0, 0, 0.3);
  /* Светлый черный с прозрачностью */
  padding: 4px;
  /* Опционально */
}

.actions {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;

  &__onTabBtns {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px !important;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  @media (max-width: 350px) {
    &__onTabBtns {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }

  &__addRequest {
    background: green !important;
    color: #fff;
  }

  &__collapseAll {
    background: orange !important;
    color: #fff;
  }
}
</style>
